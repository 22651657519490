export const alternateIds = [
  { name: 'Abilene ISD', code: 'USTXABILENE', countryCode: 'US' },
  { name: 'Alabama Driver License', code: 'USALDL', countryCode: 'US' },
  { name: 'Alabama Unique Student Identifier', code: 'USALUSI', countryCode: 'US' },
  { name: 'Alaska Driver License', code: 'USAKDL', countryCode: 'US' },
  { name: 'Alaska Student ID', code: 'USAKSID', countryCode: 'US' },
  { name: 'Antigua And Barbuda National ID', code: 'AGNID', countryCode: 'AG' },
  { name: 'Antigua And Barbuda Passport', code: 'AGPASS', countryCode: 'AG' },
  { name: 'Argentina Documento Nacional de Identidad', code: 'ARDNI', countryCode: 'AR' },
  { name: 'Arizona DOC Inmate Number', code: 'USAZDOC', countryCode: 'US' },
  { name: 'Arizona DOE Student Accountability Information System ID', code: 'USAZSAIS', countryCode: 'US' },
  { name: 'Arizona Driver License', code: 'USAZDL', countryCode: 'US' },
  { name: 'Arkansas ADE Statewide Information System', code: 'USARSIS', countryCode: 'US' },
  { name: 'Arkansas Driver License', code: 'USARDL', countryCode: 'US' },
  { name: 'Aruba National Identity Card (Cedula)', code: 'AWNIC', countryCode: 'AW' },
  { name: 'Australia Passport', code: 'AUPASS', countryCode: 'AU' },
  { name: 'BVI Social Security Number', code: 'VGSSN', countryCode: 'VG' },
  { name: 'Bahamas National Insurance Board Number', code: 'BSNIB', countryCode: 'BS' },
  { name: 'Bahamas Passport', code: 'BSPASS', countryCode: 'BS' },
  { name: 'Bahrain Identification Card', code: 'BHIDCARD', countryCode: 'BH' },
  { name: 'Bahrain Passport', code: 'BHPASS', countryCode: 'BH' },
  { name: 'Baltimore Public Schools One Card Number', code: 'USMDBCPS', countryCode: 'US' },
  { name: 'Barbados National Registration Number', code: 'BBID', countryCode: 'BB' },
  { name: 'Barbados Passport', code: 'BBPASS', countryCode: 'BB' },
  { name: 'Belize Passport', code: 'BZPASS', countryCode: 'BZ' },
  { name: 'Belize Social Security Card Number', code: 'BZSSN', countryCode: 'BZ' },
  { name: 'Bermuda Driver License', code: 'BMDL', countryCode: 'BM' },
  { name: 'Bermuda Passport', code: 'BMPASS', countryCode: 'BM' },
  { name: 'Bermuda Social Insurance Number', code: 'BMSIN', countryCode: 'BM' },
  { name: 'Bermuda Student ID Number', code: 'BMSID', countryCode: 'BM' },
  { name: 'Bolivia Cédula de Identidad', code: 'BOCEDULA', countryCode: 'BO' },
  { name: 'Brazil CPF', code: 'BRCPF', countryCode: 'BR' },
  { name: 'Brazil Passport', code: 'BRPASS', countryCode: 'BR' },
  { name: 'Brazil RG Number', code: 'BRRG', countryCode: 'BR' },
  { name: 'Brazil RIC', code: 'BRRIC', countryCode: 'BR' },
  { name: 'British Virgin Islands Driver License', code: 'VGDL', countryCode: 'VG' },
  { name: 'California CALPADS State Student ID', code: 'USCASSID', countryCode: 'US' },
  { name: 'California CDCR Inmate Number', code: 'USCACDCR', countryCode: 'US' },
  { name: 'California Driver License', code: 'USCADL', countryCode: 'US' },
  { name: 'Canada Alberta Driver License', code: 'CAABDL', countryCode: 'CA' },
  { name: 'Canada Alberta Student Number', code: 'CAASN', countryCode: 'CA' },
  { name: 'Canada British Columbia Driver License', code: 'CABCDL', countryCode: 'CA' },
  { name: 'Canada British Columbia Personal Education Number', code: 'CAPEN', countryCode: 'CA' },
  { name: 'Canada Manitoba Driver License', code: 'CAMBDL', countryCode: 'CA' },
  { name: 'Canada Manitoba Education and Training Number', code: 'CAMET', countryCode: 'CA' },
  { name: 'Canada New Brunswick Driver License', code: 'CANBDL', countryCode: 'CA' },
  { name: 'Canada Newfoundland and Labrador Driver License', code: 'CANLDL', countryCode: 'CA' },
  { name: 'Canada Northwest Territories Driver License', code: 'CANTDL', countryCode: 'CA' },
  { name: 'Canada Nova Scotia Driver License', code: 'CANSDL', countryCode: 'CA' },
  { name: 'Canada Nova Scotia Provincial Unique Student Number', code: 'CAUSI', countryCode: 'CA' },
  { name: 'Canada Nunavut Driver License', code: 'CANUDL', countryCode: 'CA' },
  { name: 'Canada Nunavut Student Information System ID', code: 'CANSIS', countryCode: 'CA' },
  { name: 'Canada Ontario Driver License', code: 'CAONDL', countryCode: 'CA' },
  { name: 'Canada Ontario Education Number', code: 'CAOEN', countryCode: 'CA' },
  { name: 'Canada Passport', code: 'CAPASS', countryCode: 'CA' },
  { name: 'Canada Prince Edward Island Driver License', code: 'CAPEDL', countryCode: 'CA' },
  { name: 'Canada Quebec Driver License', code: 'CAQCDL', countryCode: 'CA' },
  { name: 'Canada Quebec Permanent Code', code: 'CAQPC', countryCode: 'CA' },
  { name: 'Canada SIN', code: 'CASIN', countryCode: 'CA' },
  { name: 'Canada Saskatchewan Driver License', code: 'CASKDL', countryCode: 'CA' },
  { name: 'Canada Saskatchewan Learning ID', code: 'CASLID', countryCode: 'CA' },
  { name: 'Canada Yukon Driver License', code: 'CAYTDL', countryCode: 'CA' },
  { name: 'Capital Territory Driver License', code: 'AUACTDL', countryCode: 'AU' },
  { name: 'Cayman Islands National ID', code: 'KYNID', countryCode: 'KY' },
  { name: 'Chile Rol Único Tributario', code: 'CLRUT', countryCode: 'CL' },
  { name: 'Colombia Citizenship # (Cedula)', code: 'COCEDULA', countryCode: 'CO' },
  { name: 'Guatemala Citizenship # (Cedula)', code: 'GTCEDULA', countryCode: 'GT' },
  { name: 'Nicaragua Citizenship # (Cedula)', code: 'NICEDULA', countryCode: 'NI' },
  { name: 'Colombia NUIP', code: 'CONUIP', countryCode: 'CO' },
  { name: 'Colombia Passport', code: 'COPASS', countryCode: 'CO' },
  { name: 'Colorado Department of Corrections Number', code: 'USCODOC', countryCode: 'US' },
  { name: 'Colorado Driver License', code: 'USCODL', countryCode: 'US' },
  { name: 'Colorado State Assigned Student ID (SASID)', code: 'USCOSASID', countryCode: 'US' },
  { name: 'Connecticut Driver License', code: 'USCTDL', countryCode: 'US' },
  { name: 'Connecticut State Assigned Student ID', code: 'USCTSASID', countryCode: 'US' },
  { name: 'Costa Rica Cédula de identidad (Physical ID Number)', code: 'CRCEDULA', countryCode: 'CR' },
  { name: 'Costa Rica DIMEX', code: 'CRDIMEX', countryCode: 'CR' },
  { name: 'Delaware Driver License', code: 'USDEDL', countryCode: 'US' },
  { name: 'Delaware Student Information System (DELSIS)', code: 'USDEDELSIS', countryCode: 'US' },
  { name: 'District Of Columbia Driver License', code: 'USDCDL', countryCode: 'US' },
  { name: 'District of Columbia DCPS ID', code: 'USDCSID', countryCode: 'US' },
  { name: 'District of Columbia Unique Student Identifier (Charter Schools)', code: 'USDCUSI', countryCode: 'US' },
  { name: 'Dominican Cédula de Identidad', code: 'DOCEDULA', countryCode: 'DO' },
  { name: 'Dominican Republic Passport', code: 'DOPASS', countryCode: 'DO' },
  { name: 'Ecuador Cedula de Ciudadania', code: 'ECCEDULA', countryCode: 'EC' },
  { name: 'Edexcel Training ID', code: 'EDEXCEL', countryCode: 'US' },
  { name: 'Egyptian Personal ID', code: 'EGPID', countryCode: 'EG' },
  { name: 'El Salvador Documento Único de Identidad', code: 'SVDUI', countryCode: 'SV' },
  { name: 'Federal Bureau of Prisons Register Number', code: 'USBOP', countryCode: 'US' },
  { name: 'Florida DOC Inmate Number', code: 'USFLDCNO', countryCode: 'US' },
  { name: 'Florida Driver License', code: 'USFLDL', countryCode: 'US' },
  { name: 'Florida SNIF (Student Number Identifier Florida)', code: 'USFLSNIF', countryCode: 'US' },
  { name: 'France Carte nationale d’identité sécurisée', code: 'FRCNIS', countryCode: 'FR' },
  { name: 'France Institut national de la statistique et des études économiques', code: 'FRINSEE', countryCode: 'FR' },
  { name: 'Georgia Driver License', code: 'USGADL', countryCode: 'US' },
  { name: 'Georgia GDC Inmate ID', code: 'USGAGDC', countryCode: 'US' },
  { name: 'Georgia Testing Identifier', code: 'USGAGTID', countryCode: 'US' },
  { name: 'Grenada Driver License', code: 'GDDL', countryCode: 'GD' },
  { name: 'Grenada National Insurance Scheme Number', code: 'GDNIS', countryCode: 'GD' },
  { name: 'Grenada Passport', code: 'GDPASS', countryCode: 'GD' },
  { name: 'Grenada Voter ID Card', code: 'GDVID', countryCode: 'GD' },
  { name: 'Guam Dept. of Education ID', code: 'GUDOESID', countryCode: 'GU' },
  { name: 'Guam Driver License', code: 'GUDL', countryCode: 'GU' },
  { name: 'Guyana National Identification Card', code: 'GYNIC', countryCode: 'GY' },
  { name: 'Guyana National Identity Card', code: 'GYNID', countryCode: 'GY' },
  { name: 'Guyana Passport', code: 'GYPASS', countryCode: 'GY' },
  { name: 'Haiti Carte d´Identite Nationale', code: 'HTCIN', countryCode: 'HT' },
  { name: 'Haiti Numéro de la Matricule Fiscale', code: 'HTNIF', countryCode: 'HT' },
  { name: 'Hawaii Driver License', code: 'USHIDL', countryCode: 'US' },
  { name: 'Hawaii eSIS ID Number', code: 'USHIESIS', countryCode: 'US' },
  { name: 'Honduras Passport', code: 'HNPASS', countryCode: 'HN' },
  { name: 'IRS Individual Taxpayer ID Number', code: 'USITIN', countryCode: 'US' },
  { name: 'Iceland Kennitala', code: 'ISKT', countryCode: 'IS' },
  { name: 'Idaho Dept. of Corrections Offender ID', code: 'USIDDOC', countryCode: 'US' },
  { name: 'Idaho Driver License', code: 'USIDDL', countryCode: 'US' },
  { name: 'Idaho Educational Unique Identification Number( EDUID)', code: 'USIDEDUID', countryCode: 'US' },
  { name: 'Illinois DOC Number', code: 'USILDOC', countryCode: 'US' },
  { name: 'Illinois Driver License', code: 'USILDL', countryCode: 'US' },
  { name: 'Illinois Student Information System Number', code: 'USILSIS', countryCode: 'US' },
  { name: 'India Aadhaar Number', code: 'INADHAAR', countryCode: 'IN' },
  { name: 'India Passport', code: 'INPASS', countryCode: 'IN' },
  { name: 'Indiana DOE Student Test Number', code: 'USINSTN', countryCode: 'US' },
  { name: 'Indiana Dept. of Correction Number', code: 'USINDOC', countryCode: 'US' },
  { name: 'Indiana Driver License', code: 'USINDL', countryCode: 'US' },
  { name: 'Indiana Ivy Tech Community College ID', code: 'USINIVY', countryCode: 'US' },
  { name: 'Iowa Driver License', code: 'USIADL', countryCode: 'US' },
  { name: 'Iowa Student State ID', code: 'USIASSID', countryCode: 'US' },
  { name: 'Jamaica National Identification System', code: 'JMNIDS', countryCode: 'JM' },
  { name: 'Jamaica Passport', code: 'JMPASS', countryCode: 'JM' },
  { name: 'Job Corps ID', code: 'USJOBCORPSID', countryCode: 'US' },
  { name: 'Jordan Passport', code: 'JOPASS', countryCode: 'JO' },
  { name: 'Kansas DOC Registration Number', code: 'USKSKDOC', countryCode: 'US' },
  { name: 'Kansas Driver License', code: 'USKSDL', countryCode: 'US' },
  { name: 'Kansas State Student ID (KIDS system)', code: 'USKSKIDS', countryCode: 'US' },
  { name: 'Kazakhstan Individual Identification Number', code: 'KZIIN', countryCode: 'KZ' },
  { name: 'Kazakhstan Passport', code: 'KZPASS', countryCode: 'KZ' },
  { name: 'Kentucky Driver License', code: 'USKYDL', countryCode: 'US' },
  { name: 'Kentucky Student Information Systems ID', code: 'USKYSSID', countryCode: 'US' },
  { name: 'Lamar Inst Tech', code: 'USTXLAMAR', countryCode: 'US' },
  { name: 'Lebanon National ID', code: 'LBNID', countryCode: 'LB' },
  { name: 'Lebanon Passport', code: 'LBPASS', countryCode: 'LB' },
  { name: 'Louisiana DOC Inmate ID', code: 'USLADOCID', countryCode: 'US' },
  { name: 'Louisiana Driver License', code: 'USLADL', countryCode: 'US' },
  { name: 'Louisiana Secure ID', code: 'USLASID', countryCode: 'US' },
  { name: 'Maine Dept of Corrections Number', code: 'USMEMDOC', countryCode: 'US' },
  { name: 'Maine Driver License', code: 'USMEDL', countryCode: 'US' },
  { name: 'Maine MEDMS State Student ID', code: 'USMEDMS', countryCode: 'US' },
  { name: 'Maryland Division of Correction Inmate ID', code: 'USMDDOC', countryCode: 'US' },
  { name: 'Maryland Driver License', code: 'USMDDL', countryCode: 'US' },
  { name: 'Maryland State Assigned Student ID', code: 'USMDSASID', countryCode: 'US' },
  { name: 'Massachusetts Driver License', code: 'USMADL', countryCode: 'US' },
  { name: 'Massachusetts State Assigned Student Identifier', code: 'USMASASID', countryCode: 'US' },
  { name: 'Mexico Clave Única de Registro de Población', code: 'MXCURP', countryCode: 'MX' },
  { name: 'Michigan Dept. of Corrections Offender Number', code: 'USMIDOC', countryCode: 'US' },
  { name: 'Michigan Driver License', code: 'USMIDL', countryCode: 'US' },
  { name: 'Michigan Student Data System Unique Identification Code', code: 'USMIUIC', countryCode: 'US' },
  { name: 'Minnesota DoC Offender ID', code: 'MNDOCID', countryCode: 'US' },
  { name: 'Minnesota Driver License', code: 'USMNDL', countryCode: 'US' },
  { name: 'Minnesota State Student ID (MARSS)', code: 'USMNSSID', countryCode: 'US' },
  { name: 'Mississippi DOC Inmate Number', code: 'USMSDOC', countryCode: 'US' },
  { name: 'Mississippi DOE Student ID', code: 'USMSDOE', countryCode: 'US' },
  { name: 'Mississippi Driver License', code: 'USMSDL', countryCode: 'US' },
  { name: 'Missouri Driver License', code: 'USMODL', countryCode: 'US' },
  { name: 'Missouri Student Information System ID (MOSIS)', code: 'USMOSIS', countryCode: 'US' },
  { name: 'Mongolia Citizen Identity Card', code: 'MNCIC', countryCode: 'MN' },
  { name: 'Montana Driver License', code: 'USMTDL', countryCode: 'US' },
  { name: 'Montana State Student ID Number', code: 'USMTSSIDN', countryCode: 'US' },
  { name: 'Morocco National ID Card (CNIE)', code: 'MANIC', countryCode: 'MA' },
  { name: 'Mozambique Bilhete de identidade', code: 'MZBDI', countryCode: 'MZ' },
  { name: 'Nebraska DOE Student ID', code: 'USNEDE', countryCode: 'US' },
  { name: 'Nebraska Dept. of Correctional Svcs', code: 'USNEDCSID', countryCode: 'US' },
  { name: 'Nebraska Driver License', code: 'USNEDL', countryCode: 'US' },
  { name: 'Nebraska Student ID', code: 'USNESID', countryCode: 'US' },
  { name: 'Nepal National Identity Number', code: 'NPNIN', countryCode: 'NP' },
  { name: 'Nevada Driver License', code: 'USNVDL', countryCode: 'US' },
  { name: 'Nevada State Unique IdentifierSUID', code: 'USNVSUID', countryCode: 'US' },
  { name: 'Nevada Student Accountability Information Network ID', code: 'USNVSAIN', countryCode: 'US' },
  { name: 'Nevada System of Higher Education ID', code: 'USNVNSHE', countryCode: 'US' },
  { name: 'New Hampshire Driver License', code: 'USNHDL', countryCode: 'US' },
  { name: 'New Hampshire Student ID', code: 'USNHSID', countryCode: 'US' },
  { name: 'New Jersey Driver License', code: 'USNJDL', countryCode: 'US' },
  { name: 'New Jersey SMART Student ID', code: 'USNJSID', countryCode: 'US' },
  { name: 'New Mexico Driver License', code: 'USNMDL', countryCode: 'US' },
  { name: 'New Mexico Student ID System', code: 'USNMSIS', countryCode: 'US' },
  { name: 'New South Wales Driver License', code: 'AUNSWDL', countryCode: 'AU' },
  { name: 'New York DOC DIN', code: 'USNYDIN', countryCode: 'US' },
  { name: 'New York Driver License', code: 'USNYDL', countryCode: 'US' },
  { name: 'New York State Student Identification System Number', code: 'USNYSSIS', countryCode: 'US' },
  { name: 'North Carolina DPS Offender ID', code: 'USNCDPSID', countryCode: 'US' },
  { name: 'North Carolina Division of Adult Correction Offender Number', code: 'USNCDPS', countryCode: 'US' },
  { name: 'North Carolina Driver License', code: 'USNCDL', countryCode: 'US' },
  { name: 'North Carolina Student Unique Statewide Identifier', code: 'USNCUID', countryCode: 'US' },
  { name: 'North Dakota Driver License', code: 'USNDDL', countryCode: 'US' },
  { name: 'North Dakota Student State ID', code: 'USNDSSID', countryCode: 'US' },
  { name: 'Northern Territory Driver License', code: 'AUNTDDL', countryCode: 'AU' },
  { name: 'OSU Username', code: 'USOSUID', countryCode: 'US' },
  { name: 'Ohio Dept of Rehabilitation & Correction Number', code: 'USOHDRC', countryCode: 'US' },
  { name: 'Ohio Driver License', code: 'USOHDL', countryCode: 'US' },
  { name: 'Ohio Statewide Student Identifier System', code: 'USOHSSIS', countryCode: 'US' },
  { name: 'Oklahoma DOC number', code: 'USOKDOC', countryCode: 'US' },
  { name: 'Oklahoma Driver License', code: 'USOKDL', countryCode: 'US' },
  { name: 'Oklahoma WAVE Student Testing Number', code: 'USOKSTN', countryCode: 'US' },
  { name: 'Oregon DOC Inmate Number', code: 'USORDOC', countryCode: 'US' },
  { name: 'Oregon Driver License', code: 'USORDL', countryCode: 'US' },
  { name: 'Oregon Secure Student ID', code: 'USORSSID', countryCode: 'US' },
  { name: 'Panama Personal Identity Card Number', code: 'PACEDULA', countryCode: 'PA' },
  { name: 'Paraguay Cedula de Identidad Civil', code: 'PYCEDULA', countryCode: 'PY' },
  { name: 'Paris ISD', code: 'USTXPARIS', countryCode: 'US' },
  { name: 'Pennsylvania Dept of Corrections Inmate Number', code: 'USPADOC', countryCode: 'US' },
  { name: 'Pennsylvania Driver License', code: 'USPADL', countryCode: 'US' },
  { name: 'Pennsylvania PIMS SecureID', code: 'USPAPIMS', countryCode: 'US' },
  { name: 'Peru Documento Nacional de Identidad', code: 'PEDNI', countryCode: 'PE' },
  { name: 'PhilSys Number', code: 'PHPSN', countryCode: 'PH' },
  { name: 'Philippines Passport', code: 'PHPASS', countryCode: 'PH' },
  { name: 'Puerto Rico Driver License', code: 'USPRDL', countryCode: 'US' },
  { name: 'Qatar Identification Number', code: 'QAID', countryCode: 'QA' },
  { name: 'Queensland Driver License', code: 'AUQLDDL', countryCode: 'AU' },
  { name: 'Rhode Island Driver License', code: 'USRIDL', countryCode: 'US' },
  { name: 'Rhode Island State Assigned Student Identifier', code: 'USRISASI', countryCode: 'US' },
  { name: 'Saint Lucia National ID Card', code: 'LCNIC', countryCode: 'LC' },
  { name: 'Saint Lucia Passport', code: 'LCPASS', countryCode: 'LC' },
  { name: 'Salesforce Contact ID', code: 'NCCERSFID', countryCode: 'US' },
  { name: 'Saudi Arabia National ID Number', code: 'SANID', countryCode: 'SA' },
  { name: 'Saudi Arabia Passport', code: 'SAPASS', countryCode: 'SA' },
  { name: 'Shell RTC ID', code: 'SHELLRTC', countryCode: 'US' },
  { name: 'South Africa Identity Book', code: 'ZABOOK', countryCode: 'ZA' },
  { name: 'South Africa National Identity Card', code: 'ZANIC', countryCode: 'ZA' },
  { name: 'South Africa Passport', code: 'ZAPASS', countryCode: 'ZA' },
  { name: 'South Australia Driver License', code: 'AUSADL', countryCode: 'AU' },
  { name: 'South Carolina Dept. of Corrections ID', code: 'USSCDCID', countryCode: 'US' },
  { name: 'South Carolina Driver License', code: 'USSCDL', countryCode: 'US' },
  { name: 'South Carolina Student Unique Numbering System', code: 'USSCSUNS', countryCode: 'US' },
  { name: 'South Dakota Driver License', code: 'USSDDL', countryCode: 'US' },
  { name: 'South Dakota', code: 'USSDSID', countryCode: 'US' },
  { name: 'TDCJ Number', code: 'USTXTDCJ', countryCode: 'US' },
  { name: 'Tasmania Driver License', code: 'AUTASDL', countryCode: 'AU' },
  { name: 'Technical College System of Georgia Student Number', code: 'USGATCSG', countryCode: 'US' },
  { name: 'Tennessee DOC Registration Number', code: 'TNDOC', countryCode: 'US' },
  { name: 'Tennessee Driver License', code: 'USTNDL', countryCode: 'US' },
  { name: 'Tennessee EIS Student ID', code: 'USTNEISID', countryCode: 'US' },
  { name: 'Texas Driver License', code: 'USTXDL', countryCode: 'US' },
  { name: 'Texas Student Data System Unique ID (TSDS)', code: 'USTXTSDS', countryCode: 'US' },
  { name: 'Thai National Identification Number', code: 'THNIN', countryCode: 'TH' },
  { name: 'Thailand National ID', code: 'THNID', countryCode: 'TH' },
  { name: 'Trinidad And Tobago National Identification Card', code: 'TTNIC', countryCode: 'TT' },
  { name: 'Turks And Caicos Islands National Insurance  Number', code: 'TCNINO', countryCode: 'TC' },
  { name: 'Turks and Caicos Islander Status Card.', code: 'TCISC', countryCode: 'TC' },
  { name: 'US Alien Registration Number', code: 'USARN', countryCode: 'US' },
  { name: 'US Department of Defense ID Number', code: 'USDOD', countryCode: 'US' },
  { name: ' US Social Security Number', code: 'USSSN', countryCode: 'US' },
  { name: 'Ukraine Individual Identification Number', code: 'UAIIN', countryCode: 'UA' },
  { name: 'Ukraine Passport', code: 'UAPASS', countryCode: 'UA' },
  { name: 'United Kingdom Great Britain National Insurance Number', code: 'GBNINO', countryCode: 'GB' },
  { name: 'United Kingdom Passport', code: 'UKPASS', countryCode: 'GB' },
  { name: 'United Kingdom UK National Insurance Number', code: 'UKNIN', countryCode: 'GB' },
  { name: 'United States Passport', code: 'USPASS', countryCode: 'US' },
  { name: 'Uruguay Dirección Nacional de Identificación Civil', code: 'UYDNIC', countryCode: 'UY' },
  { name: 'Utah Driver License', code: 'USUTDL', countryCode: 'US' },
  { name: 'Utah State Student ID', code: 'USUTSSID', countryCode: 'US' },
  { name: 'Venezuela Cedula de Identidad', code: 'VECEDULA', countryCode: 'VE' },
  { name: 'Vermont DOC Inmate ID Number', code: 'USVTDOC', countryCode: 'US' },
  { name: 'Vermont Driver License', code: 'USVTDL', countryCode: 'US' },
  { name: 'Vermont Student ID', code: 'USVTSID', countryCode: 'US' },
  { name: 'Victoria Driver License', code: 'AUVICDL', countryCode: 'AU' },
  { name: 'Virgin Islands Student ID', code: 'USVIDOE', countryCode: 'US' },
  { name: 'Virginia DOC Number', code: 'USVADOC', countryCode: 'US' },
  { name: 'Virginia Driver License', code: 'USVADL', countryCode: 'US' },
  { name: 'Virginia Student ID', code: 'USVASID', countryCode: 'US' },
  { name: 'Washington Driver License', code: 'USWADL', countryCode: 'US' },
  { name: 'Washington State Student ID', code: 'USWASSID', countryCode: 'US' },
  { name: 'West Virginia Driver License', code: 'USWVDL', countryCode: 'US' },
  { name: 'West Virginia EIS Student ID', code: 'USWVEIS', countryCode: 'US' },
  { name: 'Western Australia Driver License', code: 'AUWADL', countryCode: 'AU' },
  { name: 'Wisconsin Driver License', code: 'USWIDL', countryCode: 'US' },
  { name: 'Wisconsin Student Number', code: 'USWIWSN', countryCode: 'US' },
  { name: 'Wyoming DOE WISER ID', code: 'USWYWISER', countryCode: 'US' },
  { name: 'Wyoming Driver License', code: 'USWYDL', countryCode: 'US' },
];
