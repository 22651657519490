import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import classNames from 'classnames';
import { Completion } from 'store/types/Completions';
import { Credential } from 'store/types/Credentials';
import React, { useCallback, useMemo, useState } from 'react';
import CredentialService from 'services/api/CredentialService';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import routes from 'store/configs/Routes';
import Spinner from 'components/shared/Spinner';
import { useWindowSize } from 'util/Window';
import { Puller } from 'util/Layout';
import { getFamilyFormatText, getTypeSafeItem } from 'util/Credentials';
import { TypeSafeCredentialDetails } from 'store/types/TypeSafeCredentialDetails';
import { BadgeImage } from '../BadgeImage';
import { pipelineTypes } from 'store/configs/CredentialTypes';

import commonStyles from 'styles/common.module.scss';
import styles from './CompletionCard.module.scss';

interface CompletionCardProps {
  item: Completion | Credential;
  isPublic?: boolean;
  showBadge?: boolean;
  size?: 'md' | 'lg';
}

const CompletionCard: React.FunctionComponent<CompletionCardProps> = ({
  item,
  isPublic = false,
  showBadge = false,
  size = 'md',
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { isMobile } = useWindowSize();
  const typeSafeItem: TypeSafeCredentialDetails = getTypeSafeItem(item);
  const docLink = typeSafeItem.Document?.Link;
  const detailLink = isPublic
    ? routes.publicBadgeDetails + '?id=' + typeSafeItem.CompletionId
    : routes.badgeDetails + '?id=' + typeSafeItem.CompletionId;
  const imageUrl = typeSafeItem.CredlyBadgeImageUrl;
  const isExpired = Boolean(typeSafeItem.ExpireDate && moment(typeSafeItem.ExpireDate) < moment());

  const dimension = useMemo(() => (size === 'lg' ? 280 : 220), [size]);

  const toggleDrawer = (newOpen: boolean) => () => {
    isMobile && setOpenDrawer(newOpen);
  };

  const handlePrintClick = useCallback(() => {
    setLoading(true);
    CredentialService.getDocumentLink(docLink)
      .then((response) => {
        setLoading(false);
        window.location.assign(response);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(error?.body, { variant: 'error' });
      });
  }, [enqueueSnackbar, docLink]);

  return (
    <Spinner loading={loading} fullPage={false}>
      {(typeSafeItem.ContentType != "Test" && (typeSafeItem.ContentFamily == undefined  || !pipelineTypes.includes(typeSafeItem.ContentFamily))) ? (
        // Badge Image Display
        <BadgeImage
          imageUrl={imageUrl}
          typeSafeItem={typeSafeItem}
          docLink={docLink}
          detailLink={detailLink}
          isExpired={isExpired}
          isMobile={isMobile}
          isPublic={isPublic}
          dimension={dimension}
          onClick={toggleDrawer(true)}
          onPrint={handlePrintClick}
        />
      ) : 
        // Tile/Card Display
        (
          <Box
          height={dimension}
          width={dimension}
          className={classNames(commonStyles.contentCard, styles.credentialCard)}
        >
          <Typography className={styles.cardTitle} variant={'body1'}>
            {typeSafeItem.Name}
          </Typography>
          {typeSafeItem.SubmissionDate && (
            <Typography variant={'body2'}>Date Earned: {moment(typeSafeItem.SubmissionDate).format('L')}</Typography>
          )}
          <Typography variant={'body2'}>{getFamilyFormatText(typeSafeItem)}</Typography>
          <Typography variant={'body2'}>{typeSafeItem.ContentType}</Typography>
          {docLink ? (
            <>
              <br />
              <Button
                onClick={handlePrintClick}
                variant={'contained'}
                color={'primary'}
                className={styles.printCredentialBtn}
              >
                {'View/Print'}
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>)
      }
      {isMobile && (
        <SwipeableDrawer
          open={openDrawer}
          onOpen={toggleDrawer(true)}
          onClose={toggleDrawer(false)}
          anchor={'bottom'}
          swipeAreaWidth={56}
          ModalProps={{
            keepMounted: false,
          }}
        >
          <div className={styles.drawerWrapper}>
            <Puller />
            {docLink && (
              <Typography
                onClick={handlePrintClick}
                className={classNames(styles.drawerOption, styles.mobileDownloadLink)}
              >
                {/* <VisibilityOutlinedIcon />  */}
                {'Print Credential'}
              </Typography>
            )}
            { typeSafeItem?.CredlyBadgeDescription ? (
              <NavLink
                to={routes.badgeDetails + '?id=' + typeSafeItem.CompletionId}
                className={classNames(styles.drawerOption, styles.mobileDetailsLink)}
              >
                View Details
              </NavLink>
            ) : (
              <Typography variant={'body2'} className={classNames(styles.drawerOption)}>Date Earned: {moment(typeSafeItem.SubmissionDate).format('L')}</Typography>
            )}
          </div>
        </SwipeableDrawer>
      )}
    </Spinner>
  );
};
export default CompletionCard;
