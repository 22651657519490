export enum ReportType {
  Completion,
  BulkCertificates,
  Assessment,
  Score,
  SSRS_Assessment,
  SuperIndendent,
  Ineligibility,
  ProgramActivity,
}

export interface Reports {
  title: string;
  subTitle: string;
  description: string;
  generationLabel: string;
  contentTypeId: number;
  reportType: ReportType;
  showDates: boolean;
  selectMultiple: boolean;
  showHelperText: boolean;
  showFailuresFlag: boolean;
  roles?: string[];
  formats: string[];
}

export const TranslateReportName = ({
  reportName,
  contentTypeId,
  completionType,
}: {
  reportName: string;
  contentTypeId?: string | number | undefined | null;
  completionType?: string;
}): string => {
  if (contentTypeId === 14) {
    return 'Craft Completions';
  } else if (contentTypeId === 13) {
    return 'Level Completions';
  } else if (contentTypeId === 9) {
    return 'Module Completions';
  } else if (reportName == 'Reports::Ineligibility') {
    return 'Retest Ineligibility';
  } else if (reportName == 'Reports::FullProgramActivityReport') {
    return 'Full Program Activity';
  } else if (reportName == 'Reports::AssessmentResultsReport') {
    if (completionType === 'practical') return 'Practical Results';
    if (completionType === 'performance_verification') return 'Performance Verification Results';
    return 'Assessment Results';
  } else if (reportName == 'Reports::AssessmentSummaryReport') {
    return 'Assessment Summary';
  }else if (reportName == 'Reports::FullAssessmentTranscript') {
    return 'Full Assessment Transcript';
  } else if (reportName == 'Reports::SiteTrainingPrescriptionReport') {
    return 'Site Training Prescription';
  } else if (reportName === 'PerformanceSubmissionReport') {
    return 'Performance Report';
  }

  return reportName;
};

export default Reports;
