import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Button, IconButton } from '@mui/material';
import classNames from 'classnames';

import styles from './NccerDonationBanner.module.scss';
import { UserContext } from 'components/UserGuard';
import { useWindowSize } from 'util/Window';
import { defaultGridItemProps } from 'util/Layout';
import { NccerDonationBannerPopup } from './NccerDonationBannerPopup';
import { Completion, Completions } from 'store/types/Completions';
import CredentialService from 'services/api/CredentialService';

const NccerDonationBanner: React.FunctionComponent = () => {
  const { isMobile } = useWindowSize();
  const user = useContext(UserContext);
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [credsState, setCreds] = useState<Completions>();
  
  const findLevel3CompletionsOrKVPV = (completions: Completions) => {
    return completions.Completions.some(Completion => {
      return (((Completion.Content.Type == "Performance Verified" || Completion.Content.Type == "Knowledge Verified") && (Completion.Content.Name.includes("Construction Superintendent") || Completion.Content.Name.includes("Construction Foreman"))) || (Completion.Content.Type == "Level" && (Completion.Content.Name.includes("Three") || Completion.Content.Name.includes("Four") || Completion.Content.Name.includes("Five"))) )
    });
  }
  const pageLoad = async () => {
    setLoading(true);
    try {
      if(user.nccerCardNumber){
        const completions = await CredentialService.getCompletions(user.nccerCardNumber);
        setCreds(completions);
        setDisplay(findLevel3CompletionsOrKVPV(completions));
      }
      else{
        setCreds(undefined);
        setDisplay(false);
      }
    } catch (error) {
      setCreds(undefined);
      setDisplay(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    pageLoad();
  }, []);
  return (
    <>
        { display && (<Grid {...defaultGridItemProps}>
          { credsState && (<NccerDonationBannerPopup completions={credsState} />)}
          <Card className={isMobile? classNames(styles.cardMobile): classNames(styles.card)} style={{padding: 0!}} sx={{overflow: 'auto'}}>
            <Box className={styles.cardContainer}>
              <div className={styles.highlight}></div>
              <div className={styles.content}>
                <CardHeader
                  disableTypography={true}
                  className={classNames(styles.cardHeader)}
                  style={{padding: 0!}}
                />
                <CardContent className={styles.cardContent} >
                  <Box display="flex" flexDirection="row" gap={2} alignItems="center" style={{padding: 0!, margin: 0!}}>
                      {!isMobile && <img src='https://www.nccer.org/media/2025/03/Action_Shot-scaled.jpg' style={{width: "318px"}}></img>}
                    <Box display="flex" flexDirection="column" gap={2} width={1} pt={4} className={isMobile? classNames(styles.cardBodyMobile): classNames(styles.cardText) }>
                      {isMobile && <img src='https://www.nccer.org/media/2025/03/Action_Shot-scaled.jpg' style={{maxWidth: "100%"}}></img>}
                      <div className={isMobile? classNames(styles.textContainerMobile): classNames(styles.textContainer) } >
                        <h4 className={isMobile? classNames(styles.titleMobile): classNames(styles.title) } >Pay it Forward</h4>
                        Congratulations! Your growth is inspiring. We hope you will consider celebrating your accomplishment by changing somebody else's life. With a donation of $25 you can pay it forward and help a student access craft training!
                        <br/>
                        <Button color="secondary" variant="outlined" target="_blank" href="https://secure.givelively.org/donate/national-center-for-construction-education-research/build-america-change-lives">
                          Donate Today
                        </Button>
                      </div>
                    </Box>
                  </Box>
                </CardContent>
              </div>
            </Box>
          </Card>
        </Grid>)}
        </>
  );
};

export default NccerDonationBanner;
