import { useCallback, useContext } from 'react';
import { Grid, Checkbox } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { UserContext } from 'components/UserGuard';
import ReportService from 'services/api/ReportService';
import { defaultFormProps } from 'util/Form';
import { nameof } from 'util/String';
import { errorMessage } from 'util/Request';
import { ProgramType } from 'store/types/User';
import { ActionButtons, CardNumberInput, ProgramSelect, TextInput, SelectInput } from './components';
import { ReportContext } from '../ReportsPage/context';

type Values = {
  nccerNumber: string,
  assessmentOnly: string,
  latestResult: string,
  moduleId: string | null,
};

const defaultValues: Values = {
  nccerNumber: '',
  assessmentOnly: 'true',
  latestResult: 'false',
  moduleId: null,
};

interface AssessmentTranscriptReportFormProps {
  selectNccerNumber?: boolean;
}

export function AssessmentTranscriptReportForm({ selectNccerNumber }: AssessmentTranscriptReportFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { closeModal, setLoading } = useContext(ReportContext);
  const { nccerCardNumber = '', programs = [] } = useContext(UserContext);
  const form = useForm<Values>({ ...defaultFormProps, defaultValues });
  const { formState, handleSubmit } = form;

  const submitDisabled = selectNccerNumber ? !formState.isValid : false;

  const handleFormSubmit = useCallback(
    async (data: Values) => {
      setLoading(true);
      try {
        const nccerNumber = selectNccerNumber ? data.nccerNumber : nccerCardNumber;
        await ReportService.getAssessmentTranscriptReport(nccerNumber, nccerCardNumber, data.assessmentOnly, data.latestResult, data.moduleId);
        //window.open(file, "_blank");
        enqueueSnackbar('Report queued successfully.', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(errorMessage(error), { variant: 'error' });
      } finally {
        closeModal();
        setLoading(false);
      }
    },
    [closeModal, enqueueSnackbar, nccerCardNumber, selectNccerNumber, setLoading]
  );

  return (
    <FormProvider {...form}>
       <Grid container spacing={4} pb={6}>
        {selectNccerNumber && (
            <CardNumberInput name="nccerNumber" label="NCCER Number" required />
        )}
      </Grid>
      <ActionButtons
        submitText="Generate File"
        disabled={submitDisabled}
        onClose={closeModal}
        onSubmit={handleSubmit(handleFormSubmit)}
      />
    </FormProvider>
  );
}
