import React, { useCallback, useState } from 'react';
import { Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CreateNccerNumberFormValues } from 'store/types/FormValues';
import {
  defaultFormProps,
  getMaxLengthValidationRule,
  getRequiredInputMessage,
  getRequiredSelectMessage,
  getValidationProps,
} from 'util/Form';
import DateFormItem from 'components/shared/DateFormItem';
import moment from 'moment';
import { alternateIds } from 'store/configs/AlternateIDs';
import { useSnackbar } from 'notistack';
import UserService from 'services/api/UserService';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { errorMessage } from 'util/Request';

import commonStyles from 'styles/common.module.scss';

const NAME_MAX_LENGTH = 32;

function sortAlpha(list: Record<string, string>[]) {
  return list.sort((a, b) => a.name.localeCompare(b.name));
}

const CreateNccerNumberModal: React.FunctionComponent<ModalProps> = ({ open, onClose }) => {
  const form = useForm<CreateNccerNumberFormValues>({
    ...defaultFormProps,
    defaultValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: undefined,
      alternateId: '',
      alternateIdTypeCode: '',
    },
  });
  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = form;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [successNumber, setSuccessNumber] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleReset = useCallback(() => {
    setSuccessMessage('');
    reset();
  }, [reset]);

  const sortedAltIds = sortAlpha(alternateIds);

  const createNccerNumber = useCallback(
    async (data: CreateNccerNumberFormValues) => {
      setLoading(true);
      try {
        const nccerNumber = await UserService.submitNccerCardNumberForm(data);
        setSuccessNumber(nccerNumber);
        setSuccessMessage(
          `NCCER Card Number created successfully. You can close this pop-up or click "Reset Form" to request another.`
        );
        // Reset everything but alt id type code.
        reset({ alternateIdTypeCode: data.alternateIdTypeCode });
      } catch (error) {
        enqueueSnackbar(errorMessage(error), { variant: 'error' });
      }
      setLoading(false);
    },
    [reset, enqueueSnackbar]
  );

  return (
    <Modal
      loading={loading}
      open={open}
      title={'NCCER Number Request Form'}
      maxWidth={'sm'}
      onClose={onClose}
      onSubmit={handleSubmit(createNccerNumber)}
      disableBackdropClick={true}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            {'Close'}
          </Button>
          <Button color={'primary'} variant={'outlined'} onClick={handleReset}>
            {'Reset Form'}
          </Button>
          <Button color={'primary'} variant={'contained'} type="submit">
            {'Submit'}
          </Button>
        </>
      }
    >
      <FormProvider {...form}>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} md={6}>
            <Typography variant={'h5'} color={'black'}>
              First Name
            </Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  {...getValidationProps('firstName', errors)}
                  className={commonStyles.inputField}
                />
              )}
              name={'firstName'}
              control={control}
              rules={{
                required: getRequiredInputMessage('first name'),
                maxLength: getMaxLengthValidationRule(NAME_MAX_LENGTH),
              }}
            />
          </Grid>
          <Grid {...defaultGridItemProps} md={6}>
            <Typography variant={'h5'} color={'black'}>
              Last Name
            </Typography>
            <Controller
              render={({ field }) => (
                <TextField {...field} {...getValidationProps('lastName', errors)} className={commonStyles.inputField} />
              )}
              name={'lastName'}
              control={control}
              rules={{
                required: getRequiredInputMessage('last name'),
                maxLength: getMaxLengthValidationRule(NAME_MAX_LENGTH),
              }}
            />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <Typography variant={'h5'} color={'black'}>
              Date of Birth
            </Typography>
            <DateFormItem
              fieldName={'dateOfBirth'}
              maxDate={moment(new Date()).startOf('day')}
              required={true}
              className={commonStyles.inputField}
            />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <Typography variant={'h5'} color={'black'}>
              ID Type
            </Typography>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth={true}
                  className={commonStyles.inputField}
                  {...getValidationProps('alternateIdTypeCode', errors)}
                >
                  {sortedAltIds.map(({ code, name }, i) => (
                    <MenuItem key={i} value={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name={'alternateIdTypeCode'}
              control={control}
              rules={{
                required: getRequiredSelectMessage('alternate ID type code'),
              }}
            />
          </Grid>
          <Grid {...defaultGridItemProps}>
            <Typography variant={'h5'} color={'black'}>
              ID Number
            </Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  {...getValidationProps('alternateId', errors)}
                  className={commonStyles.inputField}
                />
              )}
              name={'alternateId'}
              control={control}
              rules={{
                required: getRequiredInputMessage('alternate id'),
              }}
            />
          </Grid>
          {successMessage && successNumber && (
            <Grid {...defaultGridItemProps}>
              <Typography variant={'h1'} align={'center'} className={commonStyles.nccerNumberDisplay}>
                {successNumber}
              </Typography>
              <Typography variant={'body1'}>{successMessage}</Typography>
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </Modal>
  );
};
export default CreateNccerNumberModal;
