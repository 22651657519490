import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import authClient from 'services/AuthService';
import { UserContext } from 'components/UserGuard';
import Header from 'components/layout/Header';
import Modal from 'components/shared/Modal';
import Spinner from 'components/shared/Spinner';

import styles from './ReportsPage.module.scss';
import commonStyles from 'styles/common.module.scss';
import { ReportsTable } from '../ReportsTable';
import { ReportType } from 'store/types/Reports';
import TabPanel from 'components/shared/TabPanel';
import CredentialEngineService from 'services/api/CredentialEngineService';
import * as PerformanceAppService from 'services/api/PerformanceAppService';
import ReportService from 'services/api/ReportService';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useWindowSize } from 'util/Window';
import { defaultGridContainerProps, defaultGridItemProps, getPageTitle } from 'util/Layout';
import { tenantConfig } from 'config';
import { useSessionTabsContext } from 'components/SessionTabsContext';
import Roles from 'store/enums/Roles';
import { RetrievalTable, MobileReportRetrievalView } from 'components/credentials/PrintCredentials/RetrievalTable';
import { DocumentRetrieval, DocumentSource } from 'store/types/DocumentRetrieval';
import CredentialService from 'services/api/CredentialService';
import { useParams } from 'react-router-dom';
import SiteModule from 'store/enums/SiteModule';
import { ConfigContext } from 'components/ConfigGuard';
import { isFulfilled, isRejected } from 'util/Filter';
import { ProgramType } from 'store/types/User';
import { errorMessage } from 'util/Request';
import { ReportContext } from './context';
import {
  BaseReportForm,
  AssessmentCompletionsReportForm,
  AssessmentCompletionsType,
  AssessmentSummaryReportForm,
  ConstructionForemanReportForm,
  ConstructionSuperintendentReportForm,
  PerformanceEvaluationReportForm,
  SiteTrainingPrescriptionReportForm,
  AssessmentTranscriptReportForm,
} from '../forms';

export interface ReportRow {
  title: string;
  subTitle: string;
  description: ReactNode;
  generationLabel: string;
  reportForm: ReactNode;
  roles: string[];
  alternateAction?: AlternateAction;
}

enum AlternateAction {
  OpenScoreReportsTraining = 'OpenScoreReportsTraining',
  OpenScoreReportsAssessment = 'OpenScoreReportsAssessment',
  GetSuperintendentReport = 'GetSuperintendentReport',
}

const programReports: ReportRow[] = [
  // Craft Completions
  {
    title: 'Craft Completions',
    subTitle: "Select the location and date range for which you'd like to generate a report.",
    description:
      'This report shows NCCER craft completions earned during a selected date range. An NCCER craft completion is earned when a learner has completed all levels of an NCCER craft training Program.',
    generationLabel: 'Generate Report',
    reportForm: (
      <BaseReportForm
        reportType={ReportType.Completion}
        contentTypeId={14} // not arbitrary -- see credential engine
        selectMultiple
        showDates
        showHelperText
      />
    ),
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AccredApplicant,
      Roles.MasterTrainer,
      Roles.Instructor,
      Roles.AssessmentProctor,
      Roles.ModuleTestProctor,
      Roles.AssessPerfEvaluator,
      Roles.CurricPerfEvaluator,
      Roles.MobileCranePracExaminer,
      Roles.RigSigPersonPracExaminer,
      Roles.RiggerPracExaminer,
      // Certified
      Roles.CertifiedMasterTrainer,
      Roles.CertifiedInstructor,
      Roles.CertifiedAssessmentProctor,
      Roles.CertifiedModuleTestProctor,
      Roles.CertifiedAssessPerfEvaluator,
      Roles.CertifiedCurricPerfEvaluator,
      Roles.CertifiedMobileCranePracExaminer,
      Roles.CertifiedRigSigPersonPracExaminer,
    ],
  },
  // Level Completions
  {
    title: 'Level Completions',
    subTitle: "Select the location and date range for which you'd like to generate a report.",
    description:
      'This report shows NCCER level completions earned during a selected date range. An NCCER level completion is earned when a learner has completed all modules within a level of NCCER craft training.',
    generationLabel: 'Generate Report',
    reportForm: (
      <BaseReportForm
        reportType={ReportType.Completion}
        contentTypeId={13} // not arbitrary -- see credential engine
        selectMultiple
        showDates
        showHelperText
      />
    ),
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AccredApplicant,
      Roles.MasterTrainer,
      Roles.Instructor,
      Roles.AssessmentProctor,
      Roles.ModuleTestProctor,
      Roles.AssessPerfEvaluator,
      Roles.CurricPerfEvaluator,
      Roles.MobileCranePracExaminer,
      Roles.RigSigPersonPracExaminer,
      Roles.RiggerPracExaminer,
      // Certified
      Roles.CertifiedMasterTrainer,
      Roles.CertifiedInstructor,
      Roles.CertifiedAssessmentProctor,
      Roles.CertifiedModuleTestProctor,
      Roles.CertifiedAssessPerfEvaluator,
      Roles.CertifiedCurricPerfEvaluator,
      Roles.CertifiedMobileCranePracExaminer,
      Roles.CertifiedRigSigPersonPracExaminer,
    ],
  },
  // Module Completions
  {
    title: 'Module Completions',
    subTitle: "Select the location and date range for which you'd like to generate a report.",
    description:
      'This report shows NCCER module completions earned during a selected date range. An NCCER module completion is earned when a learner has completed both the module test and hands-on performance test, where applicable.',
    generationLabel: 'Generate Report',
    reportForm: (
      <BaseReportForm
        reportType={ReportType.Completion}
        contentTypeId={9} // not arbitrary -- see credential engine
        selectMultiple
        showDates
        showHelperText
      />
    ),
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AccredApplicant,
      Roles.MasterTrainer,
      Roles.Instructor,
      Roles.AssessmentProctor,
      Roles.ModuleTestProctor,
      Roles.AssessPerfEvaluator,
      Roles.CurricPerfEvaluator,
      Roles.MobileCranePracExaminer,
      Roles.RigSigPersonPracExaminer,
      Roles.RiggerPracExaminer,
      // Certified
      Roles.CertifiedMasterTrainer,
      Roles.CertifiedInstructor,
      Roles.CertifiedAssessmentProctor,
      Roles.CertifiedModuleTestProctor,
      Roles.CertifiedAssessPerfEvaluator,
      Roles.CertifiedCurricPerfEvaluator,
      Roles.CertifiedMobileCranePracExaminer,
      Roles.CertifiedRigSigPersonPracExaminer,
    ],
  },
  // Full Program Activity Report
  {
    title: 'Full Program Activity Report',
    subTitle: "Select the programs for which you'd like to generate a report.",
    description: 'All module tests taken and performance profiles completed for the program and date range selected.',
    generationLabel: 'Generate Report',
    reportForm: (
      <BaseReportForm
        reportType={ReportType.ProgramActivity}
        contentTypeId={0} // arbitrary
        selectMultiple
        showDates
        showHelperText
        showFailuresFlag
        formats={['csv', 'pdf']}
      />
    ),
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.MasterTrainer,
      Roles.Instructor,
      Roles.ModuleTestProctor,
      Roles.CurricPerfEvaluator,
      // Certified
      Roles.CertifiedMasterTrainer,
      Roles.CertifiedInstructor,
      Roles.CertifiedModuleTestProctor,
      Roles.CertifiedCurricPerfEvaluator,
    ],
  },
  // // Retest Ineligibility Report (inactive)
  // {
  //   title: 'Retest Ineligibility Report',
  //   subTitle: "Select the program for which you'd like to generate a report.",
  //   description: 'This report shows which learners are eligible for a retest in a given program.',
  //   generationLabel: 'Generate Report',
  //   reportForm: <BaseReportForm reportType={ReportType.Ineligibility} contentTypeId={0} showHelperText />,
  //   roles: [
  //     Roles.Director,
  //     Roles.Manager,
  //     Roles.ProgramContact,
  //     Roles.MasterTrainer,
  //     Roles.Instructor,
  //     Roles.ModuleTestProctor,
  //     Roles.CurricPerfEvaluator,
  //   ],
  // },
  // // Print Credentials (inactive)
  // {
  //   title: 'Print Credentials',
  //   subTitle: "Select the program and date range for which you'd like to print credentials.",
  //   description: 'Bulk print credentials for your training program.',
  //   generationLabel: 'Generate File',
  //   reportForm: <BaseReportForm reportType={ReportType.BulkCertificates} contentTypeId={0} />,
  //   roles: [Roles.Director, Roles.Manager, Roles.ProgramContact, Roles.Instructor],
  // },
  ///// APEX-7 Canceled
  // // Site Training Prescription
  // {
  //   title: 'Site Training Prescription',
  //   subTitle: "Select the location and date range for which you'd like to generate a report.",
  //   description:
  //     'This report shows training results at the selected location for the selected date range, and includes the module titles with the topic areas and the associated sections recommended for training.',
  //   generationLabel: 'Generate Report',
  //   reportForm: (
  //     <StandardReportForm
  //       programType={ProgramType.Training}
  //       onSubmit={async (values) => {
  //         // await ReportService.getSiteTrainingPrescription(
  //         //   values.programId,
  //         //   values.startDate.format('L'),
  //         //   values.endDate.format('L'),
  //         //   values.format
  //         // )
  //       }}
  //     />
  //   ),
  //   roles: [
  //     Roles.Director,
  //     Roles.Manager,
  //     Roles.ProgramContact,
  //     Roles.AssessmentProctor,
  //     Roles.CertifiedAssessmentProctor,
  //   ],
  // },
  // Training Score Report
  {
    title: 'Training Score Report',
    subTitle: '',
    description:
      'This report shows NCCER training score reports for a selected date range. A score report is generated when an individual successfully completes a module test.',
    generationLabel: 'Generate Report',
    reportForm: null,
    alternateAction: AlternateAction.OpenScoreReportsTraining,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AccredApplicant,
      Roles.MasterTrainer,
      Roles.Instructor,
      Roles.AssessmentProctor,
      Roles.ModuleTestProctor,
      Roles.AssessPerfEvaluator,
      Roles.CurricPerfEvaluator,
      Roles.MobileCranePracExaminer,
      Roles.RigSigPersonPracExaminer,
      Roles.RiggerPracExaminer,
      Roles.Learner,
      Roles.Facilitator,
      // Certified
      Roles.CertifiedMasterTrainer,
      Roles.CertifiedInstructor,
      Roles.CertifiedAssessmentProctor,
      Roles.CertifiedModuleTestProctor,
      Roles.CertifiedAssessPerfEvaluator,
      Roles.CertifiedCurricPerfEvaluator,
      Roles.CertifiedMobileCranePracExaminer,
      Roles.CertifiedRigSigPersonPracExaminer,
      // CraftPro
      Roles.CraftProLearner,
      Roles.CraftProJobsiteEvaluator,
      Roles.CraftProAdmin,
    ],
  },
  // Performance Evaluations
  {
    title: 'Digital Pipeline Performance Evaluation Report',
    subTitle:
      'Select a program and date range to create a detailed report of digitally completed evaluations. This report will create a PDF of the completed performance profiles, including program name, evaluator name, completed tasks, and signature page.',
    description: 'This report generates a PDF file for pipeline performance evaluations completed digitally.',
    generationLabel: 'Generate Report',
    reportForm: <PerformanceEvaluationReportForm programType={ProgramType.Training} />,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.Instructor,
      Roles.CurricPerfEvaluator,
      Roles.PipelinePerfEvaluator,
    ],
  },
];

const assessmentReports: ReportRow[] = [
  // Assessment Results
  {
    title: 'Assessment Results',
    subTitle: "Select the location and date range for which you'd like to generate a report.",
    description:
      'This report shows all assessments taken at the selected program for the selected date range, and includes the assessment title, candidate name, score, and result of pass or training recommended.',
    generationLabel: 'Generate Report',
    reportForm: <AssessmentCompletionsReportForm completionType={AssessmentCompletionsType.Assessment} />,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AssessmentProctor,
      Roles.CertifiedAssessmentProctor,
    ],
  },
  // Assessment Score Report
  {
    title: 'Assessment Score Report',
    subTitle: '',
    description:
      'This report shows NCCER assessment score reports. A score report is generated when an individual successfully completes an assessment.',
    generationLabel: 'Generate Report',
    reportForm: null,
    alternateAction: AlternateAction.OpenScoreReportsAssessment,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AccredApplicant,
      Roles.MasterTrainer,
      Roles.Instructor,
      Roles.AssessmentProctor,
      Roles.ModuleTestProctor,
      Roles.AssessPerfEvaluator,
      Roles.CurricPerfEvaluator,
      Roles.MobileCranePracExaminer,
      Roles.RigSigPersonPracExaminer,
      Roles.RiggerPracExaminer,
      Roles.Learner,
      Roles.Facilitator,
      // Certified
      Roles.CertifiedMasterTrainer,
      Roles.CertifiedInstructor,
      Roles.CertifiedAssessmentProctor,
      Roles.CertifiedModuleTestProctor,
      Roles.CertifiedAssessPerfEvaluator,
      Roles.CertifiedCurricPerfEvaluator,
      Roles.CertifiedMobileCranePracExaminer,
      Roles.CertifiedRigSigPersonPracExaminer,
      // CraftPro
      Roles.CraftProLearner,
      Roles.CraftProJobsiteEvaluator,
      Roles.CraftProAdmin,
    ],
  },
  // Assessment Transcript Report
  {
    title: 'Assessment Transcript Report',
    subTitle: 'Enter an NCCER Number for which you\'d like to generate an Assessment Transcript Report.',
    description:
      'This report provides the complete assessment history for an individual.',
    generationLabel: 'Generate Report',
    reportForm: <AssessmentTranscriptReportForm selectNccerNumber />,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AssessmentProctor,
      Roles.CertifiedAssessmentProctor,
    ],
  },
  // Assessment Summary
  {
    title: 'Assessment Summary',
    subTitle: "Select the location and date range for which you'd like to generate a report.",
    description:
      'This report shows a summary of assessment results at the selected program for the selected date range, and includes the assessment titles, total assessments taken, pass rates, and average scores.',
    generationLabel: 'Generate Report',
    reportForm: <AssessmentSummaryReportForm />,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AssessmentProctor,
      Roles.CertifiedAssessmentProctor,
    ],
  },
  // Construction Superintendent Certification Assessment Score Report
  {
    title: 'Construction Superintendent Certification Assessment Score Report',
    subTitle: '',
    description:
      'This report provides detailed information on the Construction Superintendent Certification Assessment and includes remediation training recommended (if applicable). This report is only available to individuals who have taken this Assessment.',
    generationLabel: 'Generate Report',
    reportForm: null,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AccredApplicant,
      Roles.MasterTrainer,
      Roles.Instructor,
      Roles.AssessmentProctor,
      Roles.ModuleTestProctor,
      Roles.AssessPerfEvaluator,
      Roles.CurricPerfEvaluator,
      Roles.MobileCranePracExaminer,
      Roles.RigSigPersonPracExaminer,
      Roles.RiggerPracExaminer,
      Roles.Learner,
      // Certified
      Roles.CertifiedMasterTrainer,
      Roles.CertifiedInstructor,
      Roles.CertifiedAssessmentProctor,
      Roles.CertifiedModuleTestProctor,
      Roles.CertifiedAssessPerfEvaluator,
      Roles.CertifiedCurricPerfEvaluator,
      Roles.CertifiedMobileCranePracExaminer,
      Roles.CertifiedRigSigPersonPracExaminer,
      // CraftPro
      Roles.CraftProLearner,
      Roles.CraftProJobsiteEvaluator,
      Roles.CraftProAdmin,
    ],
    alternateAction: AlternateAction.GetSuperintendentReport,
  },
  // Construction Superintendent Certification Assessment Score Report (Facilitator)
  {
    title: 'Construction Superintendent Certification Assessment Score Report',
    subTitle: "Enter the NCCER Numbers for which you'd like to generate a report.",
    description:
      'This report provides detailed information on the Construction Superintendent Certification Assessment and includes remediation training recommended (if applicable). This report is only available to facilitators.',
    generationLabel: 'Generate Report',
    reportForm: <ConstructionSuperintendentReportForm />,
    roles: [Roles.Facilitator],
  },
  // Construction Foreman Score Report
  {
    title: 'Construction Foreman Score Report',
    subTitle:
      'Generate a detailed Construction Foreman assessment report which includes recommended remediation training (if applicable). This report is only available to individuals who have taken this assessment.',
    description:
      'This report provides detailed information on the Construction Foreman assessment and includes remediation training recommended (if applicable). This report is only available to individuals who have taken this assessment.',
    generationLabel: 'Generate Report',
    reportForm: <ConstructionForemanReportForm />,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AccredApplicant,
      Roles.MasterTrainer,
      Roles.Instructor,
      Roles.AssessmentProctor,
      Roles.ModuleTestProctor,
      Roles.AssessPerfEvaluator,
      Roles.CurricPerfEvaluator,
      Roles.MobileCranePracExaminer,
      Roles.RigSigPersonPracExaminer,
      Roles.RiggerPracExaminer,
      Roles.Learner,
      // Certified
      Roles.CertifiedMasterTrainer,
      Roles.CertifiedInstructor,
      Roles.CertifiedAssessmentProctor,
      Roles.CertifiedModuleTestProctor,
      Roles.CertifiedAssessPerfEvaluator,
      Roles.CertifiedCurricPerfEvaluator,
      Roles.CertifiedMobileCranePracExaminer,
      Roles.CertifiedRigSigPersonPracExaminer,
      // CraftPro
      Roles.CraftProLearner,
      Roles.CraftProJobsiteEvaluator,
      Roles.CraftProAdmin,
    ],
  },
  // Construction Foreman Score Report (Facilitator)
  {
    title: 'Construction Foreman Score Report',
    subTitle:
      'Generate a detailed Construction Foreman assessment report which includes recommended remediation training (if applicable). Enter the NCCER numbers for which you would like to generate a report.',
    description:
      'This report provides detailed information on the Construction Foreman assessment and includes remediation training recommended (if applicable). This report is only available to facilitators.',
    generationLabel: 'Generate Report',
    reportForm: <ConstructionForemanReportForm selectNccerNumber />,
    roles: [Roles.Facilitator],
  },
  // Performance Evaluation
  {
    title: 'Digital Performance Verification Report',
    subTitle:
      'Select a program and date range to create a detailed report of digitally completed evaluations. This report will create a PDF of the completed performance verifications, including program name, evaluator name, completed tasks, and signature page',
    description: 'This report generates a PDF file for performance verifications completed digitally',
    generationLabel: 'Generate Report',
    reportForm: <PerformanceEvaluationReportForm programType={ProgramType.Assessment} />,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.Instructor,
      Roles.CurricPerfEvaluator,
      Roles.PipelinePerfEvaluator,
      Roles.AssessPerfEvaluator,
    ],
  },
  // Practical Exam Results
  {
    title: 'Practical Exam Results',
    subTitle: "Select the location and date range for which you'd like to generate a report.",
    description: 'This report shows practical exam details at the selected locations for the selected date range.',
    generationLabel: 'Generate Report',
    reportForm: <AssessmentCompletionsReportForm completionType={AssessmentCompletionsType.Practical} />,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AssessmentProctor,
      Roles.CertifiedAssessmentProctor,
    ],
  },
  // Performance Verifications Results
  {
    title: 'Performance Verifications Results',
    subTitle: "Select the location and date range for which you'd like to generate a report.",
    description:
      'This report shows performance verification completion details at the selected locations for the selected date range.',
    generationLabel: 'Generate Report',
    reportForm: <AssessmentCompletionsReportForm completionType={AssessmentCompletionsType.PerformanceVerification} />,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AssessmentProctor,
      Roles.CertifiedAssessmentProctor,
    ],
  },
  // Site Training Prescription
  {
    title: 'Site Training Prescription',
    subTitle: "Select the location and date range for which you'd like to generate a report.",
    description:
      'This report shows assessment topic results at the selected location for the selected date range, and includes the assessment titles with the topic areas and the associated modules recommended for training.',
    generationLabel: 'Generate Report',
    reportForm: <SiteTrainingPrescriptionReportForm />,
    roles: [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AssessmentProctor,
      Roles.CertifiedAssessmentProctor,
    ],
  },
];

export function ReportsPage() {
  const { isMobile } = useWindowSize();
  const { enqueueSnackbar } = useSnackbar();
  const { userLoading = false, roles, nccerCardNumber, programs = [] } = useContext(UserContext);
  const { sidebar } = useContext(ConfigContext);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [documentList, setDocumentList] = useState<DocumentRetrieval[]>([]);
  const [rowSelected, setRowSelected] = useState<ReportRow | undefined>();
  const { addOpenTab } = useSessionTabsContext();
  const { reportCode } = useParams<{ reportCode: string }>();

  const validProgramReports = useMemo(
    () => programReports.filter((pr) => roles && pr.roles?.some((prr) => roles.some((r) => r.role === prr))),
    [roles]
  );

  const validAssessmentReports = useMemo(
    () => assessmentReports.filter((ar) => roles && ar.roles?.some((arr) => roles.some((r) => r.role === arr))),
    [roles]
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setTabValue(Number(event.target.value));
  };

  const handleModalClose = () => {
    setRowSelected(undefined);
  };

  const getSuperintendentReport = useCallback(
    (nccerNumbers: string) => {
      setLoading(true);
      ReportService.getSuperIntendentAssessmentScoreReportLink(nccerNumbers)
        .then((response) => {
          enqueueSnackbar(`Downloading file`, { variant: 'success' });
          window.location.assign(response);
        })
        .catch((error) => {
          enqueueSnackbar(error?.body, { variant: 'error' });
        })
        .finally(() => {
          handleModalClose();
          setLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  const handleClickGenerationLabel = useCallback(
    (row: ReportRow) => {
      if (row.alternateAction) {
        switch (row.alternateAction) {
          case AlternateAction.OpenScoreReportsTraining: {
            // Open Score Reports home page in another tab.
            const link = new URL('auth', tenantConfig.scoreAppUrl);
            link.searchParams.append('id_token', authClient.getIdToken());
            const win = window.open(link, '_blank');
            addOpenTab(win);
            return;
          }
          case AlternateAction.OpenScoreReportsAssessment: {
            // Open Score Reports assessments page in another tab.
            const link = new URL('auth', tenantConfig.scoreAppUrl);
            link.searchParams.append('redirect_url', 'assessments');
            link.searchParams.append('id_token', authClient.getIdToken());
            const win = window.open(link, '_blank');
            addOpenTab(win);
            return;
          }
          case AlternateAction.GetSuperintendentReport: {
            // Directly request superintendent report.
            getSuperintendentReport(nccerCardNumber ?? '');
            return;
          }
        }
      }

      setRowSelected(row);
    },
    [addOpenTab, getSuperintendentReport, nccerCardNumber]
  );

  const handleProgress = useCallback(
    (programId: number | string, reportId: number) => {
      setLoading(true);
      let count = 0;
      const intervalId = setInterval(() => {
        CredentialEngineService.getReportStatus(programId, reportId)
          .then((response) => {
            if (response.ReportComplete) {
              window.location.assign(response.ReportUri);
              clearInterval(intervalId);
              setLoading(false);
            } else if (response.Status === 404) {
              clearInterval(intervalId);
              setLoading(false);
              enqueueSnackbar('No data was found. Please alter your parameters and try again.', { variant: 'warning' });
            } else if (count > 20) {
              clearInterval(intervalId);
              setLoading(false);
              enqueueSnackbar(
                'Request timed out. Please try again or contact customer service if the problem persists.',
                { variant: 'error' }
              );
            }

            count++;
          })
          .catch((error) => {
            enqueueSnackbar(error?.body?.Error, { variant: 'error' });
            clearInterval(intervalId);
            setLoading(false);
          });
      }, 1000);
    },
    [enqueueSnackbar]
  );

  const refreshDocumentList = useCallback(async () => {
    setLoading(true);

    try {
      // Get reports in parallel.
      const results = await Promise.allSettled([
        // Get credential engine reports.
        ReportService.getRequestedReportsByNccerNumber(nccerCardNumber ?? '', programs),
        // Get performance application reports.
        (async () => {
          const reports = await PerformanceAppService.listReports();
          return reports.map((e) => PerformanceAppService.toDocumentRetrieval(e, programs));
        })(),
      ]);

      const docs = results
        .filter(isFulfilled)
        .flatMap((e) => e.value)
        .sort((a, b) => moment(b.dateGenerated).diff(moment(a.dateGenerated)));
      setDocumentList(docs);

      // Display error if any report retrieval fails.
      const errors = results.filter(isRejected).map((e) => e.reason);
      if (errors.length) {
        throw new Error(errors.map((e) => (e instanceof Error ? e.message : String(e))).join('\n'));
      }
    } catch (error) {
      enqueueSnackbar(errorMessage(error), { variant: 'error' });
    }

    setLoading(false);
  }, [nccerCardNumber, programs, enqueueSnackbar]);

  const handlePrintClick = useCallback(
    async (document: DocumentRetrieval) => {
      if (document.documentSource === DocumentSource.CredentialEngine && document.link) {
        setLoading(true);
        try {
          const response = await CredentialService.getDocumentLink(document.link);
          setLoading(false);
          window.open(response, '_blank')?.focus();
        } catch (error) {
          setLoading(false);
          enqueueSnackbar(errorMessage(error), { variant: 'error' });
        }
      } else if (document.documentSource === DocumentSource.PerformanceApp) {
        setLoading(true);
        try {
          const response = await PerformanceAppService.getReport(document.documentId);
          if (!response.url) {
            throw new Error(response.error || 'Unable to retrieve file.');
          }
          setLoading(false);
          window.open(response.url, '_blank')?.focus();
        } catch (error) {
          setLoading(false);
          enqueueSnackbar(errorMessage(error), { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Unable to retrieve file.', { variant: 'error' });
      }
    },
    [enqueueSnackbar]
  );

  const readUrlParams = useCallback(() => {
    if (reportCode && reportCode == 'fpar') {
      // Open Full Program Activity Report form when navigating to `/reports/fpar`.
      const row = validProgramReports.find((value) => value.title == 'Full Program Activity Report');
      if (row) {
        handleClickGenerationLabel(row);
      }
    }
  }, [handleClickGenerationLabel, reportCode, validProgramReports]);

  useEffect(() => {
    readUrlParams();
  }, [readUrlParams]);

  const mobileReportView = useCallback(
    (report: ReportRow, index: number) => {
      return (
        <Grid {...defaultGridItemProps} key={`report-${index}`}>
          <Box className={commonStyles.contentCard}>
            <Typography variant="h5">{report.title}</Typography>
            <Typography sx={{ my: 2 }}>{report.description}</Typography>
            <Button variant="outlined" color={'secondary'} fullWidth onClick={() => handleClickGenerationLabel(report)}>
              <Typography fontWeight="bold" color={'secondary'}>
                {report.generationLabel}
              </Typography>
            </Button>
          </Box>
        </Grid>
      );
    },
    [handleClickGenerationLabel]
  );

  return (
    <ReportContext.Provider
      value={{ setLoading, closeModal: handleModalClose, refreshDocumentList, getSuperintendentReport, handleProgress }}
    >
      {!isMobile && <Header />}
      <div className={styles.reportsHeader}>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} md={12}>
            <Typography variant={'h2'} className={styles.reportsTitle}>
              {getPageTitle(sidebar, SiteModule.Reports) || 'Reports'}
            </Typography>
          </Grid>
        </Grid>
        {isMobile && (
          <Box sx={{ mt: 3 }}>
            <FormControl fullWidth>
              <Select value={tabValue.toString()} onChange={handleDropdownChange}>
                {validProgramReports && <MenuItem value={0}>Training</MenuItem>}
                {validAssessmentReports?.length && <MenuItem value={1}>Assessment</MenuItem>}
                {<MenuItem value={2}>Retrieve File</MenuItem>}
              </Select>
            </FormControl>
          </Box>
        )}
      </div>
      <Spinner loading={userLoading || loading}>
        <div className={commonStyles.pageContent}>
          {!isMobile && (
            <>
              <div className={commonStyles.contentCard}>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: '#CAD2E6' }}>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                      {validProgramReports && <Tab label={'Training'} className={commonStyles.tabText} />}
                      {validAssessmentReports?.length && <Tab label={'Assessment'} className={commonStyles.tabText} />}
                      <Tab label={'Retrieve File'} className={commonStyles.tabText} />
                    </Tabs>
                  </Box>
                  {tabValue == 2 && (
                    <>
                      <Typography paragraph={true} className={styles.reportsDescription} sx={{ paddingTop: '16px' }}>
                        {
                          'Depending on the size of your report, please allow up to 24 hours for your report to generate. '
                        }
                        <strong>{'Retrieve File'}</strong>
                        {' will appear in the far right hand column when your report is ready. '}
                        <strong>{'Processing'}</strong>
                        {' will appear if your report is still generating.'}
                      </Typography>
                      <Typography paragraph={true} className={styles.reportsDescription}>
                        {
                          'NOTE: reports will remain on this tab for 30 days, after which time the report will need to be regenerated, if needed.'
                        }
                      </Typography>
                    </>
                  )}
                </Box>
              </div>
              <TabPanel value={tabValue} index={0}>
                <Grid container className={commonStyles.subHeaderTextWrapper}>
                  <Grid item xs={12}>
                    <ReportsTable
                      reports={validProgramReports}
                      className={styles.reportsTable}
                      onClick={handleClickGenerationLabel}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid container className={commonStyles.subHeaderTextWrapper}>
                  <Grid item xs={12}>
                    <ReportsTable
                      reports={validAssessmentReports}
                      className={styles.reportsTable}
                      onClick={handleClickGenerationLabel}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Grid container className={commonStyles.subHeaderTextWrapper}>
                  <Grid item xs={12}>
                    <RetrievalTable
                      documents={documentList}
                      className={styles.reportsTable}
                      handlePrintClick={handlePrintClick}
                      refreshDocumentList={refreshDocumentList}
                      showType={true}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </>
          )}

          {isMobile && (
            <>
              <Grid {...defaultGridContainerProps}>
                {tabValue === 0 &&
                  validProgramReports.map((report, index) => {
                    return mobileReportView(report, index);
                  })}
                {tabValue === 1 &&
                  validAssessmentReports.map((report, index) => {
                    return mobileReportView(report, index);
                  })}
                {tabValue === 2 && (
                  <MobileReportRetrievalView
                    documents={documentList}
                    handlePrintClick={handlePrintClick}
                    refreshDocumentList={refreshDocumentList}
                  />
                )}
              </Grid>
            </>
          )}
        </div>
        <Modal
          maxWidth={'md'}
          loading={loading}
          open={Boolean(rowSelected)}
          title={rowSelected?.title}
          subTitleText={rowSelected?.subTitle}
          headerBodyDivider={true}
          onClose={handleModalClose}
        >
          {rowSelected?.reportForm}
        </Modal>
      </Spinner>
    </ReportContext.Provider>
  );
}
