import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TableContainer,
  Typography,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import CredentialService from 'services/api/CredentialService';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import { useWindowSize } from 'util/Window';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Add, Remove } from '@mui/icons-material';
import { Completion } from 'store/types/Completions';
import greenCheckCircle from 'assets/icons/greenCheckCircle.svg';
import moment from 'moment';
import { categoryText, courseList } from 'store/types/CraftProCredentialObjects';
import { getFamilyFormatText, getTypeSafeItem } from 'util/Credentials';
import { TypeSafeCredentialDetails } from 'store/types/TypeSafeCredentialDetails';

import commonStyles from 'styles/common.module.scss';
import styles from './BadgeDetailsPage.module.scss';
import { BadgeItem } from '../BadgeItem';

export function BadgeDetails() {
  const { isMobile } = useWindowSize();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const completionId = searchParams.get('id');
  const isCraftPro = searchParams.get('iscp') === 'true';
  const [expanded, setExpanded] = React.useState<string | false>('');
  const [completionData, setCompletionData] = React.useState<Completion>();
  const [typeSafeItem, setTypeSafeItem] = React.useState<TypeSafeCredentialDetails>();

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const pageLoad = useCallback(async () => {
    setLoading(true);
    CredentialService.getCompletionById(completionId ?? '')
      .then((response) => {
        setCompletionData(response);
        setTypeSafeItem(getTypeSafeItem(response));
        setLoading(false);
      })
      .catch((error) => {
        setCompletionData(undefined);
        enqueueSnackbar(error, { variant: 'error' });
        setLoading(false);
      });
  }, [completionId, enqueueSnackbar]);

  useEffect(() => {
    pageLoad();
  }, [pageLoad]);

  const handlePrintClick = useCallback(() => {
    if (completionData?.Document?.Link) {
      setLoading(true);
      CredentialService.getDocumentLink(completionData.Document.Link)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error, { variant: 'error' });
        });
    }
  }, [completionData, enqueueSnackbar]);

  return (
    <Spinner loading={loading}>
      {!isCraftPro && (
        <div className={commonStyles.pageContent}>
          <Box className={commonStyles.contentCard} sx={{ mb: 3 }}>
            <Grid {...defaultGridContainerProps}>
              <Grid {...defaultGridItemProps} xl={2} lg={3} md={4}>
                {typeSafeItem ? ( 
                  isMobile ? (
                    <div className={styles.mobileBadgeWrapper}>
                      <BadgeItem typeSafeItem={typeSafeItem}/>
                    </div>
                  ) : (
                    <BadgeItem typeSafeItem={typeSafeItem}/> )
                ) : isMobile 
                  ? (
                    <div className={styles.mobileBadgeWrapper}>
                      <img src={completionData?.Content?.CredlyBadgeImageUrl} className={styles.badgeImage} />
                    </div>
                  ) : (
                    <img src={completionData?.Content?.CredlyBadgeImageUrl} className={styles.badgeImage} />
                )}
              </Grid>
              <Grid {...defaultGridItemProps} xl={10} lg={9} md={8}>
                {completionData?.Document?.Link && isMobile && (
                  <Button color={'primary'} variant={'contained'} fullWidth onClick={handlePrintClick} sx={{ mb: 2 }}>
                    {'Print Credential'}
                  </Button>
                )}
                <Box display="flex" alignItems="start" gap={4}>
                  {completionData?.SubmissionDate && (
                    <Box pb={1}>
                      <Typography variant={'body2'} pb={1}>
                        Issue Date
                      </Typography>
                      <Typography variant={'body1'} fontWeight="bold">
                        {moment(completionData?.SubmissionDate).format('MM/DD/YY')}
                      </Typography>
                    </Box>
                  )}
                  {completionData?.ExpirationDate && (
                    <Box pb={1}>
                      <Typography variant={'body2'} pb={1} whiteSpace="nowrap">
                        Expire Date
                      </Typography>
                      <Typography variant={'body1'} fontWeight="bold">
                        {moment(completionData?.ExpirationDate).format('MM/DD/YY')}
                      </Typography>
                    </Box>
                  )}
                  {completionData?.Content.Type === 'Module' && completionData?.Content.Family && (
                    <Box pb={1}>
                      <Typography variant={'body2'} pb={1}>
                        Craft
                      </Typography>
                      <Typography variant={'body1'} fontWeight="bold">
                        {completionData?.Content.Family}
                      </Typography>
                    </Box>
                  )}
                  {completionData?.Document?.Link && !isMobile && (
                    <Button color={'primary'} variant={'contained'} onClick={handlePrintClick} sx={{ ml: 'auto' }}>
                      {'Print Credential'}
                    </Button>
                  )}
                </Box>

                <Grid {...defaultGridItemProps} mt={4}>
                  <Typography variant={'body2'} pb={1}>
                    {'Description'}
                  </Typography>
                  <Typography variant={'body1'} pb={1}>
                    {completionData?.Content?.CredlyBadgeDescription
                      ? completionData?.Content?.CredlyBadgeDescription
                      : 'The details associated with this achievement are still under construction.'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {completionData?.Content?.CredlyBadgeSkills && (
              <Box pt={2}>
                <Typography variant={'body2'} pb={1}>
                  Skill Tags
                </Typography>
                <Box component="ul" display="flex" flexWrap="wrap" gap={2}>
                  {completionData.Content.CredlyBadgeSkills.split(',').map((skill, key) => {
                    return (
                      <Box
                        component="li"
                        key={key}
                        display="flex"
                        alignItems="center"
                        gap={1}
                        border="solid 1px #9fa09f"
                        borderRadius={2}
                        p={1}
                      >
                        <img src={greenCheckCircle} />
                        <Typography variant={'body1'}>{skill}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Box>
        </div>
      )}

      {/* CraftPro COURSES TABLE */}
      {isCraftPro && !isMobile && (
        <TableContainer className={styles.coursesTable}>
          <MuiTable size={'medium'}>
            <TableHead>
              <TableRow className={styles.titleWrapper}>
                <TableCell className={styles.title}>
                  <Typography variant={'h6'}>Courses ({courseList.length})</Typography>
                </TableCell>
              </TableRow>
              <TableRow className={styles.columnHeader}>
                <TableCell className={styles.headerCellWrapper}>
                  <Typography variant={'body2'} className={styles.headerText}>
                    Title
                  </Typography>
                </TableCell>
                <TableCell className={styles.headerCellWrapper}>
                  <Typography variant={'body2'} className={styles.headerText}>
                    Craft
                  </Typography>
                </TableCell>
                <TableCell className={styles.headerCellWrapper}>
                  <Typography variant={'body2'} className={styles.headerText}>
                    Overview
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courseList.map((item, index) => (
                <TableRow className={styles.dataRow} key={index}>
                  <TableCell className={styles.columnOne}>
                    <Typography variant={'body2'} className={styles.text}>
                      {item.title}
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.columnTwo}>
                    <Typography variant={'body2'}>{item.craft}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'body2'}>{item.description}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      )}

      {/* CraftPro MOBILE COURSES TABLE */}
      {isCraftPro && isMobile && (
        <TableContainer className={styles.mobileCoursesTable}>
          <MuiTable size={'small'}>
            <TableHead>
              <TableRow className={styles.titleWrapper}>
                <TableCell className={styles.title}>
                  <Typography variant={'h6'} className={styles.mobileTitle}>
                    {categoryText}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={styles.headerWrapper}>
                <TableCell className={styles.header}>
                  <Typography variant={'body2'} className={styles.headerText}>
                    Title
                  </Typography>
                </TableCell>
              </TableRow>

              {courseList.map((item, index) => (
                <TableRow className={styles.mobileDataRow} key={index}>
                  <Accordion
                    expanded={expanded === 'accordion' + index}
                    onChange={handleAccordionChange('accordion' + index)}
                    className={styles.accordionBox}
                  >
                    <AccordionSummary
                      expandIcon={expanded === 'accordion' + index ? <Remove /> : <Add />}
                      className={
                        expanded === 'accordion' + index
                          ? styles.expandedAccordionHeader
                          : styles.collapsedAccordionHeader
                      }
                    >
                      <Typography variant={'body2'} className={styles.accordionFaceplate}>
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordionContent}>
                      <Typography variant={'body2'} className={styles.accordionCategory}>
                        Craft
                      </Typography>
                      <Typography variant={'body2'} className={styles.accordionCategoryValue}>
                        {item.craft}
                      </Typography>
                      <Typography variant={'body2'} className={styles.accordionCategory}>
                        Overview
                      </Typography>
                      <Typography variant={'body2'} className={styles.accordionCategoryValue}>
                        {item.description}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      )}
    </Spinner>
  );
}
