import { useCallback, useContext } from 'react';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { UserContext } from 'components/UserGuard';
import ReportService from 'services/api/ReportService';
import { ProgramType } from 'store/types/User';
import { defaultFormProps } from 'util/Form';
import { errorMessage } from 'util/Request';
import { nameof } from 'util/String';
import { ActionButtons, DateRangeInput, SelectInput, ProgramSelect } from './components';
import { ReportContext } from '../ReportsPage/context';

const DATE_FORMAT = 'YYYY-MM-DD';

type Values = {
  programId: string[];
  startDate: moment.Moment; // Inclusive start date.
  endDate: moment.Moment; // Inclusive end date.
  format: 'csv' | 'pdf';
};

type Nullable<T> = { [P in keyof T]: T[P] | null };
type FieldValues = Nullable<Values>;
const defaultValues: FieldValues = {
  programId: [],
  startDate: null,
  endDate: null,
  format: 'csv',
};

const fileFormats = [
  { value: 'csv', label: 'CSV (Excel)' },
  { value: 'pdf', label: 'PDF' },
];

export function AssessmentSummaryReportForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { nccerCardNumber: requesterCardNumber = '', programs = [] } = useContext(UserContext);
  const { closeModal, setLoading } = useContext(ReportContext);
  const form = useForm<FieldValues>({
    ...defaultFormProps,
    defaultValues,
  });
  const { formState, handleSubmit } = form;

  const submitDisabled = !formState.isValid;

  const handleFormSubmit = useCallback(
    async ({ programId, startDate, endDate, format }: FieldValues) => {
      if (!programId || !startDate || !endDate || !format) return;

      setLoading(true);
      try {
        await ReportService.getAssessmentSummary(
          requesterCardNumber,
          programId.join(','),
          startDate.format(DATE_FORMAT),
          endDate.format(DATE_FORMAT),
          format
        );
        enqueueSnackbar('Report queued successfully.', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(errorMessage(error), { variant: 'error' });
      } finally {
        closeModal();
        setLoading(false);
      }
    },
    [closeModal, enqueueSnackbar, requesterCardNumber, setLoading]
  );

  return (
    <FormProvider {...form}>
      <Grid container spacing={4} pb={6}>
        <ProgramSelect
          name={nameof<FieldValues>('programId')}
          programs={programs}
          programType={ProgramType.Assessment}
          multi
        />
        <DateRangeInput nameStartDate={nameof<FieldValues>('startDate')} nameEndDate={nameof<FieldValues>('endDate')} />
        <SelectInput
          name={nameof<FieldValues>('format')}
          options={fileFormats}
          label="File Format"
          placeholder="File Format"
        />
      </Grid>
      <ActionButtons
        submitText="Generate Report"
        disabled={submitDisabled}
        onClose={closeModal}
        onSubmit={handleSubmit(handleFormSubmit)}
      />
    </FormProvider>
  );
}
