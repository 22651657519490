import moment from 'moment';
import { Box, Button, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { BadgeItem } from '../BadgeItem';

import { TypeSafeCredentialDetails } from 'store/types/TypeSafeCredentialDetails';
import styles from './BadgeImage.module.scss';

interface BadgeImageProps {
  imageUrl?: string;
  docLink: string;
  detailLink: string;
  isExpired: boolean;
  isMobile: boolean;
  dimension?: number;
  typeSafeItem: TypeSafeCredentialDetails;
  isPublic?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onPrint?: React.MouseEventHandler<HTMLButtonElement>;
}

export function BadgeImage({
  imageUrl,
  docLink,
  detailLink,
  isExpired,
  isMobile,
  dimension = 220,
  isPublic = false,
  typeSafeItem,
  onClick,
  onPrint,
}: BadgeImageProps) {
  return (
    <Box
      onClick={onClick}
      className={styles.overlayContainer}
    >
        <BadgeItem typeSafeItem={typeSafeItem} dimension={dimension} isPublic={isPublic}/>
      {isExpired && (
        <Box p={1}>
          <Typography variant="body2" className={styles.expiredTag}>
            Expired
          </Typography>
        </Box>
      )}
      {!isMobile && (
        <Box className={styles.hiddenOverlay}>
          {docLink && (
            <Button onClick={onPrint} className={styles.downloadLink}>
              Print Credential
            </Button>
          )}
          { typeSafeItem?.CredlyBadgeDescription ? ( 
            <NavLink to={detailLink} className={styles.viewDetailsLink}>
              View Details
            </NavLink>
          ) : (
            <p className={styles.viewDetails}>Date Earned: {moment(typeSafeItem?.SubmissionDate).format('L')}</p>
          )}
        </Box>
        )}
    </Box>
  );
}
