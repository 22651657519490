import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AccountCircle, GridView, GridViewRounded, ViewAgenda, ViewAgendaOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography, Select, MenuItem, 
  SelectChangeEvent,
} from '@mui/material';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import classNames from 'classnames';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Link, useHistory, useParams } from 'react-router-dom';
import Captcha from 'react-google-recaptcha';
import searchNormal from 'assets/icons/searchNormal.svg';
import NccerLogo from 'assets/images/nccer/NCCER_logo_wordmark_positive_RGB.png';
import CraftProCard from 'components/credentials/CraftProCard';
import Spinner from 'components/shared/Spinner';
import Table from 'components/shared/Table';
import TabPanel from 'components/shared/TabPanel';
import { TooltipInfo } from 'components/shared/TooltipInfo';
import { tenantConfig } from 'config';
import CredentialService from 'services/api/CredentialService';
import { pipelineTypes } from 'store/configs/CredentialTypes';
import routes from 'store/configs/Routes';
import { Completion, Completions } from 'store/types/Completions';
import { Credential } from 'store/types/Credentials';
import { TableColumn } from 'store/types/Table';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { useWindowSize } from 'util/Window';
import { errorMessage } from 'util/Request';
import { CompletionsGrid } from '../CompletionsGrid';
import { CredentialsGrid } from '../CredentialsGrid';

import commonStyles from 'styles/common.module.scss';
import { common } from '@mui/material/colors';

const paginationLength = 10;

export function OnlineVerification() {
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const { searchText } = useParams<{ searchText: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();

  const captchaRef = useRef<Captcha>(null);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState(searchText ?? '');
  const [tabValue, setTabValue] = useState(0);
  // false = list/grid, true = tile
  const [viewType, setViewType] = useState(true);
  // undefined -> search loading or pending | null -> no results
  const [completions, setCompletions] = useState<Completions>();

  const credentialSearch = useCallback(
    async (search: string) => {
      setLoading(true);
      try {
        // Reset captcha if existing.
        if (captchaRef.current?.getValue()) captchaRef.current.reset();
        // Get captcha token.
        const token = (await captchaRef.current?.executeAsync()) ?? '';

        const results = await CredentialService.publicSearch(search, token);
        setCompletions(results);
      } catch (error) {
        enqueueSnackbar(errorMessage(error), { variant: 'error' });
        setCompletions(undefined);
      }
      setLoading(false);
    },
    [enqueueSnackbar]
  );

  const printDocument = useCallback(
    async (documentLink: string) => {
      setLoading(true);
      try {
        const response = await CredentialService.getDocumentLink(documentLink);
        window.location.assign(response);
      } catch (error) {
        enqueueSnackbar(errorMessage(error), { variant: 'error' });
      }
      setLoading(false);
    },
    [enqueueSnackbar]
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      // Input validation.
      if (!searchVal) {
        enqueueSnackbar('Please provide a search parameter.', { variant: 'error' });
        return;
      }

      // Perform explicit credential search if search parameter does not change.
      // Allows resubmit of search on network error.
      if (searchText === searchVal) {
        await credentialSearch(searchVal);
      }

      // Update path param.
      history.replace({ pathname: `${routes.onlineVerification}/${searchVal}` });
    },
    [credentialSearch, enqueueSnackbar, history, searchText, searchVal]
  );

  const handleSearchInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setSearchVal(event.currentTarget.value),
    []
  );
  const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: number) => setTabValue(newValue), []);
    const handleDropdownChange = (event: SelectChangeEvent) => {
      setTabValue(Number(event.target.value));
    };
  const handleListView = useCallback(() => setViewType(false), []);
  const handleGridView = useCallback(() => setViewType(true), []);

  const {
    trainingCrafts,
    trainingLevels,
    trainingModules,
    trainingTests,
    trainingCount,

    cpLearningPaths,
    cpCourses,
    craftProCount,

    knowledgeVerifications,
    performanceVerifications,
    jlaCount,

    personnelCertifications,
    craftCertifications,
    certificateCount,

    fullModulePipelineCompletions,
    writtenPipelineCompletions,
    performancePipelineCompletions,
    pipelineCount,
  } = useMemo(() => separateCompletions(completions), [completions]);

  const {
    pipelineCompletionColumns,
    credentialColumns,
    assessmentColumns,
    craftCompletionColumns,
    levelCompletionColumns,
    moduleCompletionColumns,
    trainingTestColumns,
    craftProCourseColumns,
    craftProLearningPathColumns,
  } = useMemo(() => tableColumns(printDocument), [printDocument]);

  // Search on page load or search parameter change.
  useEffect(() => {
    async function pageLoad() {
      setSearchVal(searchText ?? '');

      if (searchText) await credentialSearch(searchText);
    }

    void pageLoad();
  }, [credentialSearch, searchText]);

  return (
    <Box bgcolor="whitesmoke" minHeight="100vh" >
      <div style={isMobile && completions? { background: '#172535' } : {}}>
        
          <Link to={routes.home}>
            <img src={NccerLogo} />
          </Link>
          <Container sx={{ pb: 2 }}>
          <Typography variant="h1" textAlign={isMobile? "left" :"center"} style={isMobile && completions? { color: 'white' } : {}}>
            Credential Search
          </Typography>
          <Typography color="text.primary" fontSize="h5.fontSize" mb={4} textAlign={isMobile? "left" :"center"} style={isMobile && completions? { display: 'none' } : {}}>
            Search and verify individuals' credentials with their NCCER Number OR their Last Name and SSN
          </Typography>
          <Typography color="text.primary" fontSize="h5.fontSize" mb={4} textAlign={isMobile? "left" :"center"} style={isMobile && completions? { color: 'white' } : {display: 'none'}}>
            Search and verify individuals' credentials 
          </Typography>
          {/* Search form. */}
          <Container maxWidth="sm" >
            <form onSubmit={handleSubmit}>
              <Box display="flex" alignItems="center" gap={2} pb={4}>
                <TextField
                  placeholder="12345678 or Smith 123-45-6789"
                  value={searchVal}
                  size="small"
                  onChange={handleSearchInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchNormal} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ backgroundColor: 'white' }}
                />
                <Button type="submit" variant="contained">
                  Search
                </Button>
                <Captcha ref={captchaRef} sitekey={tenantConfig.captchaSiteKey} size="invisible" />
              </Box>
            </form>
          </Container>
        </Container>
      </div>

      <Container sx={{ pb: 2 }}>
        <Spinner loading={loading}>
          

          {/* Completions. */
          //here
          }
          {completions && (
            <>
              <Grid container style={isMobile? {paddingTop: 16} : {}}>
                <Grid item xs={12} sm={8} className={commonStyles.nameplateWrapper}>
                  <Grid item xs={2} md={1} xl={1} className={commonStyles.smallProfileImageWrapper}>
                    {completions.LogoUrl ? (
                      <img className={commonStyles.smallProfileImage} src={completions.LogoUrl} />
                    ) : (
                      <AccountCircle className={commonStyles.smallProfileImage}></AccountCircle>
                    )}
                  </Grid>
                  <Grid item xs={10} md={11} xl={11} className={commonStyles.nameplate}>
                    <Typography variant="h2">
                      {completions.FirstName} {completions.LastName}
                    </Typography>
                    <Typography variant="body2">{completions.CardNumber}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {isMobile && (
                <Grid container style={{padding: 9}}>
                <Select sx={{ width: '87%' }} value={tabValue.toString()} onChange={handleDropdownChange}>
                  <MenuItem value={0}>{`Accredited Training  (${trainingCount.toString()})`}</MenuItem>
                  <MenuItem value={1}>{`CraftPro  (${craftProCount.toString()})`}</MenuItem>
                  <MenuItem value={2}>{`Journey-Level Assessments  (${jlaCount.toString()})`}</MenuItem>
                  <MenuItem value={3}>{`Certifications  (${certificateCount.toString()})`}</MenuItem>
                  <MenuItem value={4}>{`Pipeline OQ  (${pipelineCount.toString()})`}</MenuItem>
                </Select>
                {!viewType ? (
                  <IconButton sx={{ width: '13%' }} onClick={handleGridView}>
                    <GridViewRoundedIcon />
                  </IconButton>
                ) : (
                  <IconButton sx={{ width: '13%' }} onClick={handleListView}>
                    <FormatListBulletedRoundedIcon />
                  </IconButton>
                )}
              </Grid>
              )}
              {!isMobile && ( <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons={false}
                visibleScrollbar={true}
                className={commonStyles.ovOverflowEnforcer}
              >
                <Tab
                  label="Accredited Training"
                  icon={<Typography className={commonStyles.credentialCounter}>{trainingCount}</Typography>}
                  iconPosition="end"
                  className={commonStyles.tabText}
                />
                <Tab
                  label="CraftPro"
                  icon={<Typography className={commonStyles.credentialCounter}>{craftProCount}</Typography>}
                  iconPosition="end"
                  className={commonStyles.tabText}
                />
                <Tab
                  label={'Journey-Level Assessments'}
                  icon={<Typography className={commonStyles.credentialCounter}>{jlaCount}</Typography>}
                  iconPosition="end"
                  className={commonStyles.tabText}
                />
                <Tab
                  label="Certifications"
                  icon={<Typography className={commonStyles.credentialCounter}>{certificateCount}</Typography>}
                  iconPosition="end"
                  className={commonStyles.tabText}
                />
                <Tab
                  label="Pipeline OQ"
                  icon={<Typography className={commonStyles.credentialCounter}>{pipelineCount}</Typography>}
                  iconPosition="end"
                  className={commonStyles.tabText}
                />
              </Tabs>
              )}
              {!isMobile && (<Grid {...defaultGridContainerProps} pb={2}>
                <Grid {...defaultGridItemProps} lg={10} md={9} sm={8}>
                  <Typography variant="body2" className={commonStyles.tabDescriptor}>
                    {tabValue == 0 &&
                      'This training is delivered by organizations that have met the NCCER accreditation and personnel training requirements. NCCER curriculum is modular in format and presented as a multi-level curriculum that can be used to meet the DOL Office of Apprenticeship time requirements.'}
                    {tabValue == 1 &&
                      'CraftPro training consists of NCCER online courses selected by a company based on their unique project and skill requirements, and do not require accreditation.'}
                    {tabValue == 2 &&
                      'These assessments and performance verifications are intended to measure the knowledge and skills of experienced craft professionals.'}
                    {tabValue == 3 &&
                      'Certifications represent completion of both knowledge and performance evaluations. Note: this section also displays program personnel certifications.'}
                    {tabValue == 4 &&
                      'Pipeline OQ are written and/or performance completions needed to qualify individuals to perform activities on a pipeline according to operator qualification requirements.'}
                  </Typography>
                </Grid>
                <Grid {...defaultGridItemProps} lg={2} md={3} sm={4} className={commonStyles.viewSelectorWrapper}>
                  <ButtonGroup variant="contained" className={commonStyles.viewSelector}>
                    <IconButton
                      onClick={handleGridView}
                      className={classNames(commonStyles.gridViewButton, viewType ? commonStyles.activeViewButton : '')}
                    >
                      {viewType ? (
                        <GridViewRounded className={commonStyles.gridViewIcon} />
                      ) : (
                        <GridView className={commonStyles.gridViewIcon} />
                      )}
                      <Typography variant="body2" className={commonStyles.viewSelectorText}>
                        Grid
                      </Typography>
                    </IconButton>
                    <IconButton
                      onClick={handleListView}
                      className={classNames(
                        commonStyles.listViewButton,
                        !viewType ? commonStyles.activeViewButton : ''
                      )}
                    >
                      {viewType ? (
                        <ViewAgendaOutlined className={commonStyles.listViewIcon} />
                      ) : (
                        <ViewAgenda className={commonStyles.listViewIcon} />
                      )}
                      <Typography variant="body2" className={commonStyles.viewSelectorText}>
                        List
                      </Typography>
                    </IconButton>
                  </ButtonGroup>
                </Grid>
              </Grid>)}

              <TabPanel value={tabValue} index={0}>
                <Grid {...defaultGridContainerProps}>
                  {!viewType && (
                    <Grid {...defaultGridItemProps}>
                      {
                        <Table
                          list={trainingCrafts}
                          columns={craftCompletionColumns}
                          noResultsText="No crafts earned"
                          showPagination={trainingCrafts && trainingCrafts.length > paginationLength}
                          category="Completed Crafts"
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={trainingLevels}
                          columns={levelCompletionColumns}
                          noResultsText="No levels earned"
                          showPagination={trainingLevels && trainingLevels.length > paginationLength}
                          category="Completed Levels"
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={trainingModules}
                          columns={moduleCompletionColumns}
                          noResultsText="No modules earned"
                          showPagination={trainingModules && trainingModules.length > paginationLength}
                          category="Completed Modules"
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={trainingTests}
                          columns={trainingTestColumns}
                          noResultsText="No tests completed"
                          showPagination={trainingTests && trainingTests.length > paginationLength}
                          category="Specialized Test Certificates"
                          className={commonStyles.listViewTable}
                        />
                      }
                    </Grid>
                  )}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <CompletionsGrid
                        title={
                          <>
                            Craft Completions
                            <TooltipInfo title="Hover over a badge to see badge details or print the completion." />
                          </>
                        }
                        completions={trainingCrafts}
                        isPublic
                      />

                      <CompletionsGrid
                        title={
                          <>
                            Level Completions
                            <TooltipInfo title="Hover over a badge to see badge details or print the completion." />
                          </>
                        }
                        completions={trainingLevels}
                        isPublic
                      />

                      <CompletionsGrid
                        title={
                          <>
                            Module Completions
                            <TooltipInfo title="Hover over a badge to see badge details or print the completion." />
                          </>
                        }
                        completions={trainingModules}
                        isPublic
                      />

                      <CredentialsGrid
                        title="Specialized Test Certificates"
                        credentials={trainingTests}
                        showBadge={false}
                        isPublic
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid {...defaultGridContainerProps}>
                  {!viewType && (
                    <Grid {...defaultGridItemProps}>
                      {
                        <Table
                          list={cpLearningPaths}
                          columns={craftProLearningPathColumns}
                          category="Learning Path Credentials"
                          noResultsText="No learning path credentials earned"
                          showPagination={cpLearningPaths && cpLearningPaths.length > paginationLength}
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={cpCourses}
                          columns={craftProCourseColumns}
                          category="Course Completions"
                          noResultsText="No course completions earned"
                          showPagination={cpCourses && cpCourses.length > paginationLength}
                          className={commonStyles.listViewTable}
                        />
                      }
                    </Grid>
                  )}
                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant="h6" className={commonStyles.credentialCategory}>
                        Learning Paths
                        <TooltipInfo title="Collections of NCCER courses, curated by companies to meet specific training goals." />
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    cpLearningPaths &&
                    cpLearningPaths.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CraftProCard item={item} />
                      </Grid>
                    ))}
                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <Typography variant="h6" className={commonStyles.credentialCategory}>
                        All Courses
                        <TooltipInfo title="Individual training requirements that make up each Learning Path." />
                      </Typography>
                    </Grid>
                  )}
                  {viewType &&
                    cpCourses &&
                    cpCourses.map((item, index) => (
                      <Grid {...defaultGridItemProps} md={3} key={index}>
                        <CraftProCard item={item} />
                      </Grid>
                    ))}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Grid {...defaultGridContainerProps}>
                  {!viewType && (
                    <Grid {...defaultGridItemProps}>
                      {
                        <Table
                          list={knowledgeVerifications}
                          columns={assessmentColumns}
                          category="Knowledge Verified"
                          noResultsText="No credentials earned"
                          showPagination={knowledgeVerifications && knowledgeVerifications.length > paginationLength}
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={performanceVerifications}
                          columns={assessmentColumns}
                          category="Performance Verified"
                          noResultsText="No credentials earned"
                          showPagination={
                            performanceVerifications && performanceVerifications.length > paginationLength
                          }
                          className={commonStyles.listViewTable}
                        />
                      }
                    </Grid>
                  )}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <CredentialsGrid
                        title="Knowledge Verified"
                        credentials={knowledgeVerifications}
                        size="lg"
                        isPublic
                      />

                      <CredentialsGrid
                        title="Performance Verified"
                        credentials={performanceVerifications}
                        size="lg"
                        isPublic
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <Grid {...defaultGridContainerProps}>
                  {!viewType && (
                    <Grid {...defaultGridItemProps}>
                      {
                        <Table
                          list={personnelCertifications}
                          columns={credentialColumns}
                          category="Program Personnel Certifications"
                          noResultsText="No credentials earned"
                          showPagination={personnelCertifications && personnelCertifications.length > paginationLength}
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={craftCertifications}
                          columns={credentialColumns}
                          category="Craft Certifications"
                          noResultsText="No credentials earned"
                          showPagination={craftCertifications && craftCertifications.length > paginationLength}
                          className={commonStyles.listViewTable}
                        />
                      }
                    </Grid>
                  )}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <CredentialsGrid
                        title="Program Personnel Certifications"
                        credentials={personnelCertifications}
                        showBadge
                        isPublic
                      />

                      <CredentialsGrid title="Craft Certifications" credentials={craftCertifications} isPublic />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <Grid {...defaultGridContainerProps}>
                  {!viewType && (
                    <Grid {...defaultGridItemProps}>
                      {
                        <Table
                          list={fullModulePipelineCompletions}
                          columns={pipelineCompletionColumns}
                          category="Completed Modules"
                          noResultsText="No module completions earned"
                          showPagination={
                            fullModulePipelineCompletions && fullModulePipelineCompletions.length > paginationLength
                          }
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={writtenPipelineCompletions}
                          columns={pipelineCompletionColumns}
                          category="Written Completions"
                          noResultsText="No written completions earned"
                          showPagination={
                            writtenPipelineCompletions && writtenPipelineCompletions.length > paginationLength
                          }
                          className={commonStyles.listViewTable}
                        />
                      }
                      {
                        <Table
                          list={performancePipelineCompletions}
                          columns={pipelineCompletionColumns}
                          category="Performance Completions"
                          noResultsText="No performance completions earned"
                          showPagination={
                            performancePipelineCompletions && performancePipelineCompletions.length > paginationLength
                          }
                          className={commonStyles.listViewTable}
                        />
                      }
                    </Grid>
                  )}

                  {viewType && (
                    <Grid {...defaultGridItemProps}>
                      <CompletionsGrid
                        title="Completed Modules"
                        completions={fullModulePipelineCompletions}
                        size="lg"
                        isPublic
                      />

                      <CompletionsGrid
                        title="Written Completions"
                        completions={writtenPipelineCompletions}
                        size="lg"
                        isPublic
                      />

                      <CompletionsGrid
                        title="Performance Completions"
                        completions={performancePipelineCompletions}
                        size="lg"
                        isPublic
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
            </>
          )}

          {/* No completions found. */}
          {completions === null && <Typography textAlign="center">No credentials were found.</Typography>}
        </Spinner>
      </Container>
    </Box>
  );
}

function separateCompletions(completions: Completions | null | undefined) {
  const trainingModules = completions?.Completions?.filter(
    (item) => item.Content.Type === 'Module' && !item.Content.Format && !pipelineTypes.includes(item.Content.Family)
  )
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId))
    .sort((a, b) => a.Content.Family?.localeCompare(b.Content.Family));
  const trainingLevels = completions?.Completions?.filter((item) => item.Content.Type === 'Level').sort((a, b) =>
    a.Content.Name.localeCompare(b.Content.Name)
  );
  const trainingCrafts = completions?.Completions?.filter((item) => item.Content.Type === 'Craft').sort((a, b) =>
    a.Content.Name.localeCompare(b.Content.Name)
  );
  const trainingTests = completions?.Credentials?.filter((item) => item.ContentType == 'Test').sort((a, b) =>
    a.CredentialName.localeCompare(b.CredentialName)
  );
  const trainingCount =
    (trainingModules?.length ?? 0) +
    (trainingLevels?.length ?? 0) +
    (trainingCrafts?.length ?? 0) +
    (trainingTests?.length ?? 0);

  const knowledgeVerifications = completions?.Credentials?.filter(
    (item) => item.ContentType == 'Knowledge Verified'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const performanceVerifications = completions?.Credentials?.filter(
    (item) => item.ContentType == 'Performance Verified'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const jlaCount = (knowledgeVerifications?.length ?? 0) + (performanceVerifications?.length ?? 0);

  const craftCertifications = completions?.Credentials?.filter((item) => item.ContentType == 'Certified').sort((a, b) =>
    a.CredentialName.localeCompare(b.CredentialName)
  );
  const personnelCertifications = completions?.Credentials?.filter(
    (item) => item.ContentType == 'Program Personnel'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const certificateCount = (craftCertifications?.length ?? 0) + (personnelCertifications?.length ?? 0);

  const fullModulePipelineCompletions = completions?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && !item.Content.Format
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const writtenPipelineCompletions = completions?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && item.Content.Format === 'Written'
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const performancePipelineCompletions = completions?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && item.Content.Format === 'Performance'
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const pipelineCount =
    (fullModulePipelineCompletions?.length ?? 0) +
    (writtenPipelineCompletions?.length ?? 0) +
    (performancePipelineCompletions?.length ?? 0);

  const cpCourses = completions?.Completions?.filter(
    (item) => item.Content.Type.localeCompare('Craftpro Course', undefined, { sensitivity: 'base' }) === 0
  ).sort((a, b) => a.Content.Name.localeCompare(b.Content.Name));
  const cpLearningPaths = completions?.Completions?.filter(
    (item) => item.Content.Type.localeCompare('Craftpro Path', undefined, { sensitivity: 'base' }) === 0
  ).sort((a, b) => a.Content.Name.localeCompare(b.Content.Name));
  const craftProCount = (cpCourses?.length ?? 0) + (cpLearningPaths?.length ?? 0);

  return {
    trainingCrafts,
    trainingLevels,
    trainingModules,
    trainingTests,
    trainingCount,

    cpLearningPaths,
    cpCourses,
    craftProCount,

    knowledgeVerifications,
    performanceVerifications,
    jlaCount,

    personnelCertifications,
    craftCertifications,
    certificateCount,

    fullModulePipelineCompletions,
    writtenPipelineCompletions,
    performancePipelineCompletions,
    pipelineCount,
  };
}

function tableColumns(printDocument: (documentLink: string) => void | Promise<void>) {
  const pipelineCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module ID</Typography>,
      render: (_, record) => <Typography>{record.Content.ContentId}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
  ];

  const credentialColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'ExpireDate',
      label: <Typography fontWeight="bold">Expiration Date</Typography>,
      render: (_, record) => (
        <Typography>{record.ExpireDate ? moment(record.ExpireDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) => (
        <ViewPrintDocument label="Print" link={record.Documents?.at(0)?.Link} onPrint={printDocument} />
      ),
    },
  ];

  const assessmentColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) => (
        <ViewPrintDocument label="Print" link={record.Documents?.at(0)?.Link} onPrint={printDocument} />
      ),
    },
  ];

  const craftCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) => <ViewPrintDocument label="Print" link={record.Document?.Link} onPrint={printDocument} />,
    },
  ];

  const levelCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Craft</Typography>,
      render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) => <ViewPrintDocument label="Print" link={record.Document?.Link} onPrint={printDocument} />,
    },
  ];

  const moduleCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module ID</Typography>,
      render: (_, record) => <Typography>{record.Content.ContentId}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Craft</Typography>,
      render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) => <ViewPrintDocument label="Print" link={record.Document?.Link} onPrint={printDocument} />,
    },
  ];

  const trainingTestColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) => (
        <ViewPrintDocument label="View/Print" link={record.Documents?.at(0)?.Link} onPrint={printDocument} />
      ),
    },
  ];

  const craftProCourseColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    // {
    //   dataIndex: "Content",
    //   label: <Typography fontWeight="bold">Craft</Typography>,
    //   render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    // },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) => (
        <ViewPrintDocument label="View/Print" link={record.Document?.Link} onPrint={printDocument} />
      ),
    },
  ];

  const craftProLearningPathColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) => (
        <ViewPrintDocument label="View/Print" link={record.Documents?.at(0)?.Link} onPrint={printDocument} />
      ),
    },
  ];

  return {
    pipelineCompletionColumns,
    credentialColumns,
    assessmentColumns,
    craftCompletionColumns,
    levelCompletionColumns,
    moduleCompletionColumns,
    trainingTestColumns,
    craftProCourseColumns,
    craftProLearningPathColumns,
  };
}

interface ViewPrintDocumentProps {
  label: string;
  link: string | undefined;
  onPrint: (link: string) => void | Promise<void>;
}

function ViewPrintDocument({ label, link, onPrint }: ViewPrintDocumentProps) {
  const handlePrint = useCallback(() => link && onPrint(link), [link, onPrint]);

  return link ? (
    <Button onClick={handlePrint} variant="contained" color="primary">
      {label}
    </Button>
  ) : (
    <Typography color="lightgray">None</Typography>
  );
}
