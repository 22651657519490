/**
 * Tech debt:
 *
 * The separate forms (Craft Completions, Level Completions, Assessment
 * Results, etc.) should be split out into separate files and utilize the
 * shared components from `./components.tsx`.
 */

import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { UserContext } from 'components/UserGuard';
import DateFormItem from 'components/shared/DateFormItem';
import { GenerateReportFormValues } from 'store/types/FormValues';
import { ReportType } from 'store/types/Reports';
import { Program } from 'store/types/User';
import CredentialEngineService from 'services/api/CredentialEngineService';
import ReportService from 'services/api/ReportService';
import { defaultFormProps, getRequiredValidationRule, getValidationPropsFromError } from 'util/Form';
import { ReportContext } from '../ReportsPage/context';

const DATE_FORMAT = 'YYYY-MM-DD';

const defaultValues: GenerateReportFormValues = {
  programId: '',
  startDate: null,
  endDate: null,
  format: 'csv',
  showFailures: false,
};

export interface BaseReportFormProps {
  reportType: ReportType;
  contentTypeId: number;
  selectMultiple?: boolean;
  showDates?: boolean;
  showHelperText?: boolean;
  showFailuresFlag?: boolean;
  formats?: string[];
}

export function BaseReportForm({
  reportType,
  contentTypeId,
  selectMultiple = false,
  showDates = false,
  showHelperText = false,
  showFailuresFlag = false,
  formats = ['csv'],
}: BaseReportFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { nccerCardNumber, programs } = useContext(UserContext);
  const { closeModal, handleProgress, setLoading, refreshDocumentList } = useContext(ReportContext);
  const [selectedPrograms, setSelectedPrograms] = useState<[]>([]);

  const handleProgramSelection = (event: SelectChangeEvent<[]>) => {
    const current = event.target.value as [];
    const newList: [] = [];
    for (let i = 0; i < current.length; i++) {
      const index = getIndexOfProgram(newList, current[i][0]);
      if (index >= 0) {
        newList.splice(index, 1);
      } else {
        newList.push(current[i]);
      }
    }
    setSelectedPrograms(newList);
  };

  const determineProgramList = (program: Program): boolean => {
    if (contentTypeId == 9 || contentTypeId == 13 || contentTypeId == 14 || contentTypeId == 0) {
      return program.programType == 'Training Program' && program.programDesignation == 'Registered';
    } else if (
      contentTypeId == 20 ||
      contentTypeId == 21 ||
      contentTypeId == 22 ||
      contentTypeId == 23 ||
      contentTypeId == 24
    ) {
      return program.programType == 'Assessment Program' && program.programDesignation == 'Registered';
    } else {
      return false;
    }
  };

  const form = useForm<GenerateReportFormValues>({
    ...defaultFormProps,
    defaultValues,
  });
  const { control, formState, handleSubmit, watch } = form;

  const startDateValue = watch('startDate');
  const endDateValue = watch('endDate');

  const submitButtonDisabled: boolean = useMemo(
    () =>
      !formState.isValid ||
      !formState.isDirty ||
      (showDates && !startDateValue) ||
      (showDates && !endDateValue) ||
      (selectMultiple && selectedPrograms?.length == 0),
    [
      formState.isValid,
      formState.isDirty,
      showDates,
      startDateValue,
      endDateValue,
      selectMultiple,
      selectedPrograms?.length,
    ]
  );

  const getIndexOfProgram = (list: [], id: string) => {
    for (let i = 0; i < list.length; i++) if (list[i][0] == id) return i;
    return -1;
  };

  const handleFormSubmit = useCallback(
    ({ programId, startDate, endDate, format, showFailures }: GenerateReportFormValues) => {
      if (reportType === ReportType.Completion) {
        ReportService.queueReport(
          programId,
          contentTypeId,
          moment(startDate).format(DATE_FORMAT),
          moment(endDate).format(DATE_FORMAT),
          nccerCardNumber ?? '',
          format
        )
          .then(() => {
            //handleProgress(programId, response.ReportId);
            refreshDocumentList();
            enqueueSnackbar(`Report queued successfully.`, { variant: 'success' });
          })
          .catch((error) => {
            enqueueSnackbar(error?.body?.Error, { variant: 'error' });
          })
          .finally(() => {
            closeModal();
            setLoading(false);
          });
      } else if (reportType === ReportType.BulkCertificates) {
        CredentialEngineService.queueBulkCertificatesReport(
          programId,
          moment(startDate).format(DATE_FORMAT),
          moment(endDate).format(DATE_FORMAT)
        )
          .then((response) => {
            handleProgress(programId, response.ReportId);
          })
          .catch((error) => {
            enqueueSnackbar(error?.body?.Error, { variant: 'error' });
          });
      } else if (reportType === ReportType.Ineligibility) {
        ReportService.queueReportIneligibility(programId, nccerCardNumber ?? '', format)
          .then(() => {
            refreshDocumentList();
            enqueueSnackbar(`Report queued successfully.`, { variant: 'success' });
          })
          .catch((error) => {
            enqueueSnackbar(error?.body?.Error, { variant: 'error' });
          })
          .finally(() => {
            closeModal();
            setLoading(false);
          });
      } else if (reportType === ReportType.ProgramActivity) {
        ReportService.queueReportFullProgramActivity(
          programId,
          moment(startDate).format(DATE_FORMAT),
          moment(endDate).format(DATE_FORMAT),
          nccerCardNumber ?? '',
          format,
          showFailures
        )
          .then(() => {
            refreshDocumentList();
            enqueueSnackbar(`Report queued successfully.`, { variant: 'success' });
          })
          .catch((error) => {
            enqueueSnackbar(error?.body?.Error, { variant: 'error' });
          })
          .finally(() => {
            closeModal();
            setLoading(false);
          });
      }
    },
    [
      reportType,
      contentTypeId,
      nccerCardNumber,
      refreshDocumentList,
      enqueueSnackbar,
      closeModal,
      setLoading,
      handleProgress,
    ]
  );

  const handleUpdateMethod = useCallback(
    (formValues: GenerateReportFormValues) => {
      if (selectMultiple) {
        const arr: string[] = [];
        selectedPrograms.forEach((element) => {
          arr.push(element[0]);
        });
        formValues.programId = arr.join(',');
        setSelectedPrograms([]);
      }
      handleFormSubmit(formValues);
    },
    [handleFormSubmit, selectMultiple, selectedPrograms]
  );

  return (
    <FormProvider {...form}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>
            {contentTypeId == 0 ? (selectMultiple ? 'Programs' : 'Program') : selectMultiple ? 'Locations' : 'Location'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {selectMultiple ? (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Select
                  // {...field}
                  {...getValidationPropsFromError(error)}
                  inputRef={field.ref}
                  placeholder={'Search for organizations or programs'}
                  fullWidth={true}
                  displayEmpty={true}
                  value={selectedPrograms}
                  onChange={handleProgramSelection}
                  multiple={selectMultiple}
                  renderValue={(selected) =>
                    selected
                      .map(function (elem) {
                        return elem[1];
                      })
                      .join(', ')
                  }
                >
                  <MenuItem value={''} disabled>
                    {'Search for organizations or programs'}
                  </MenuItem>
                  {programs
                    ?.filter((p) => determineProgramList(p))
                    .sort((a, b) => a.programName.localeCompare(b.programName))
                    .map((value) => (
                      <MenuItem key={value.programId} value={[value.programId, value.programName]}>
                        <Checkbox checked={getIndexOfProgram(selectedPrograms, value.programId) > -1} />
                        <ListItemText primary={value.programName} />
                      </MenuItem>
                    ))}
                </Select>
              )}
              name={'programId'}
              control={control}
            />
          ) : (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  {...getValidationPropsFromError(error)}
                  inputRef={field.ref}
                  placeholder={'Search for organizations or programs'}
                  fullWidth={true}
                  displayEmpty={true}
                >
                  <MenuItem value={''} disabled>
                    {'Search for organizations or programs'}
                  </MenuItem>
                  {programs
                    ?.filter((p) => determineProgramList(p))
                    .sort((a, b) => a.programName.localeCompare(b.programName))
                    .map((value) => (
                      <MenuItem key={value.programId} value={value.programId}>
                        {value.programName}
                      </MenuItem>
                    ))}
                </Select>
              )}
              name={'programId'}
              control={control}
              rules={{ required: getRequiredValidationRule('program', true, true) }}
            />
          )}
        </Grid>
        {showDates && (
          <>
            <Grid item xs={12}>
              <Typography variant={'h6'} display="inline">
                {'Date Range '}
              </Typography>
              <Typography variant={'body2'} display="inline">
                {'(select/enter up to 12 month date range)'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <DateFormItem
                disabled={false}
                fieldName={'startDate'}
                minDate={endDateValue ? moment(endDateValue).subtract(12, 'months') : undefined}
                maxDate={endDateValue ? moment(endDateValue) : moment(new Date()).startOf('day')}
                required={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateFormItem
                disabled={false}
                fieldName={'endDate'}
                minDate={startDateValue ? moment(startDateValue) : undefined}
                maxDate={startDateValue ? moment(startDateValue).add(12, 'months') : undefined}
                required={false}
              />
            </Grid>
          </>
        )}
        {formats?.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant={'h6'}>File Format</Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    {...getValidationPropsFromError(error)}
                    inputRef={field.ref}
                    fullWidth={true}
                    displayEmpty={false}
                  >
                    {formats.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value == 'csv' ? value.toUpperCase() + ' (Excel)' : value.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name={'format'}
                control={control}
              />
            </Grid>
          </>
        )}
        {showFailuresFlag && (
          <>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    label={'Include Failures?'}
                    control={<Checkbox {...field} checked={field.value} color={'primary'} />}
                  />
                )}
                name={'showFailures'}
                control={control}
              />
            </Grid>
          </>
        )}
        {showHelperText && (
          <Grid item xs={12}>
            <Typography paragraph={true} sx={{ marginBottom: '0px' }}>
              {'Once you select '}
              <strong>{'Generate Report'}</strong>
              {' you will need to go to the '}
              <strong>{'Retrieve File'}</strong>
              {
                ' tab to view your report. Depending on the size of the your report, please allow up to 24 hours for your report to generate.'
              }
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container={true} justifyContent={'space-between'} pt={6}>
        <Button color={'primary'} variant={'outlined'} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          type={'submit'}
          onClick={handleSubmit(handleUpdateMethod)}
          disabled={submitButtonDisabled}
        >
          {contentTypeId == 0 ? 'Generate File' : 'Generate Report'}
        </Button>
      </Grid>
    </FormProvider>
  );
}
