import { Box, Typography } from '@mui/material';
import NccerLogo from 'assets/images/nccer/logo-negative.png'

import classNames from 'classnames';
import { TypeSafeCredentialDetails } from 'store/types/TypeSafeCredentialDetails';
import styles from './BadgeItem.module.scss';

interface BadgeItemProps {
  dimension?: number;
  typeSafeItem: TypeSafeCredentialDetails;
  isPublic?: boolean;
}

function getLevelStyle(title: string) {
  if (title.includes('One') || title.includes('1')) { return styles.nccerBadgeLevelOne;}
  else if (title.includes('Two') || title.includes('2')) { return styles.nccerBadgeLevelTwo;}
  else if (title.includes('Three') || title.includes('3')) { return styles.nccerBadgeLevelThree;}
  else if (title.includes('Four') || title.includes('4')) { return styles.nccerBadgeLevelFour;}
  else if (title.includes('Five') || title.includes('5')) { return styles.nccerBadgeLevelFive;}
  else { return styles.nccerBadgeSingleLevel; }
}

function getLevelNumber(title: string) {
  if (title.includes('One') || title.includes('1')) { return '1';}
  else if (title.includes('Two') || title.includes('2')) { return '2';}
  else if (title.includes('Three') || title.includes('3')) { return '3';}
  else if (title.includes('Four') || title.includes('4')) { return '4';}
  else if (title.includes('Five') || title.includes('5')) { return '5';}
  else { return ''; }
}

export function BadgeItem({
  dimension = 220,
  isPublic = false,
  typeSafeItem,
}: BadgeItemProps) {
  return (
        <Box
          height={dimension}
          width={dimension}
          className={classNames(styles.credentialCard, (typeSafeItem.ContentType === 'Level' ? styles.nccerBadgeLevel : styles.nccerBadgeBlue), (typeSafeItem.ContentType === 'Level' ? getLevelStyle(typeSafeItem.Name) : ''))}
        >
          <Box className={(typeSafeItem.ContentType === 'Craft' ? styles.craftBoxGrid : styles.boxGrid)}>
            <Typography className={styles.completion} variant={'body2'}>{typeSafeItem.ContentType} {getLevelNumber(typeSafeItem.Name)} Completion</Typography>
            <img className={styles.completion} src={NccerLogo} alt={'logo'} />
          </Box>
          {typeSafeItem.ContentType === 'Module' && !isPublic ? (
            <Typography className={styles.cardTitle} variant={'body1'}>
              {typeSafeItem.Name} ({typeSafeItem.ContentId}) </Typography>
          ) : (
            <Typography className={(typeSafeItem.ContentType === 'Craft' ? styles.craftTitle :  styles.cardTitle)} variant={'body1'}>
              {typeSafeItem.Name}
            </Typography>
          )}
        </Box>
      
  );
}
