import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from 'components/UserGuard';
import Modal from 'components/shared/Modal';
import { CurrentUser } from 'store/types/User';
import { DONATION_POPUP_HIDDEN_LABEL, getExpDate } from 'util/LocalStorage';
import { Completion, Completions } from 'store/types/Completions';

export function NccerDonationBannerPopup(props: {completions: Completions}) {
  const subtitle = "Congratulations! Your growth is inspiring. We hope you will consider celebrating your accomplishment by changing somebody else's life. With a donation of $25 you can pay it forward and help a student access craft training!";
  const { pathname } = useLocation();
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [credsState, setCreds] = useState<Completions>();

  const isSuppressedDonationPopup = (): boolean  => {
    const key = DONATION_POPUP_HIDDEN_LABEL + user.nccerCardNumber;
    const retrievedObject = localStorage.getItem(key);
    const value = retrievedObject && JSON.parse(retrievedObject);
  
    return value == null || !noNewCerts(value.CredentialCount, listOfCreds(props.completions)) || !noNewCerts(value.KvPvCount, listOfKVPV(props.completions));
  }

  const [open, setOpen] = useState(() => {
    return isSuppressedDonationPopup();
  });
  const closeModal = useCallback(() => setOpen(false), []);

  const dismissModal = useCallback(() => {
    suppressDonationPopup();
    closeModal();
  }, [closeModal, user.nccerCardNumber]);


const suppressDonationPopup = (): void  => {
  // This is using cookies/local storage. We can change this if needed
  const key = DONATION_POPUP_HIDDEN_LABEL + user.nccerCardNumber;
  
  const jsonToStore = {CredentialCount: listOfCreds(props.completions), KvPvCount: listOfKVPV(props.completions)}
  localStorage.setItem(key, JSON.stringify(jsonToStore));
}

  return (
    <DonationPopupContext.Provider value={{ setLoading, closeModal, dismissModal }}>
      <Modal loading={loading} open={open} title={"Pay it forward"} subTitleText={subtitle} onClose={dismissModal}>
        <Box display="flex" flexDirection="column" gap={2} alignItems="center">
          <img src='https://www.nccer.org/media/2025/03/Girl_Scouts-scaled.jpg' width={"100%"}></img>             
          <Box display="flex" flexDirection="column" gap={2} width={1} pt={4}>
          <Button color="primary" variant="contained" target="_blank" href="https://secure.givelively.org/donate/national-center-for-construction-education-research/build-america-change-lives">
              Donate Now
            </Button>
            <Button color="secondary" variant="outlined" onClick={dismissModal}>
              No Thanks
            </Button>
          </Box>
        </Box>
      </Modal>
    </DonationPopupContext.Provider>
  );
}

function listOfCreds(completions: Completions ): string[] {
  const listOfCreds: string[] = [] 
  
  completions.Completions.forEach(Completion => {
    if( Completion.Content.Type == "Level" && (Completion.Content.Name.includes("Three") || Completion.Content.Name.includes("Four") || Completion.Content.Name.includes("Five")) ){
      listOfCreds.push(Completion.Content.Name);
    }
  });
  return listOfCreds;
}

function listOfKVPV(completions: Completions ): string[] {
  const listOfKVPV: string[] = [] 
  
  completions.Completions.forEach(Completion => {
    if( Completion.Content.Type == "Performance Verified" || Completion.Content.Type == "Knowledge Verified" ){
      listOfKVPV.push(Completion.Content.Name);
    }
  });
  return listOfKVPV;
}

function noNewCerts(stored: string[], currentCerts: string[] ): boolean {
  return currentCerts.toString() == stored.toString();
}


interface DonationPopupContext {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: () => void;
  dismissModal: () => void;
}

const DonationPopupContext = createContext<DonationPopupContext>({
  setLoading: () => undefined,
  closeModal: () => undefined,
  dismissModal: () => undefined,
});
