import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Controller, FieldValues, UseControllerProps, useWatch } from 'react-hook-form';
import { isAssessmentProgram, isTrainingProgram, Program, ProgramType } from 'store/types/User';
import {
  NCCER_NUMBER_LIST_REGEXP,
  NCCER_NUMBER_REGEXP,
  getRequiredValidationRule,
  getValidationPropsFromError,
} from 'util/Form';

import DateFormItem from 'components/shared/DateFormItem';

/**
 * Shared form components.
 */

export interface ActionsProps {
  submitText: string;
  disabled?: boolean;
  onClose: () => void;
  onSubmit: (e: React.MouseEvent) => void;
}

export function ActionButtons({ submitText, disabled, onClose, onSubmit }: ActionsProps) {
  return (
    <Box display="flex" justifyContent="space-between">
      <Button color="primary" variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button color="primary" variant="contained" type="submit" onClick={onSubmit} disabled={disabled}>
        {submitText}
      </Button>
    </Box>
  );
}

export function CardNumberInput<T extends FieldValues>(props: TextInputProps<T>) {
  return (
    <TextInput
      placeholder="123456"
      {...props}
      rules={{
        pattern: { value: NCCER_NUMBER_REGEXP, message: 'NCCER Number should contain numbers only.' },
        ...props.rules,
      }}
    />
  );
}

export function CardNumberMultiInput<T extends FieldValues>(props: TextInputProps<T>) {
  return (
    <TextInput
      placeholder={'123456,987654'}
      {...props}
      rules={{
        pattern: {
          value: NCCER_NUMBER_LIST_REGEXP,
          message: 'NCCER Number should contain numbers, spaces, and commas only.',
        },
        ...props.rules,
      }}
    />
  );
}

export interface DateRangeInputProps {
  nameStartDate: string;
  nameEndDate: string;
}

export function DateRangeInput({ nameStartDate, nameEndDate }: DateRangeInputProps) {
  const startDateValue = useWatch({ name: nameStartDate });
  const endDateValue = useWatch({ name: nameStartDate });

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" display="inline">
          {'* Date Range '}
        </Typography>
        <Typography variant="body2" display="inline">
          {'(select/enter up to 12 month date range)'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <DateFormItem
          fieldName={nameStartDate}
          required
          minDate={endDateValue ? moment(endDateValue).subtract(12, 'months') : undefined}
          maxDate={endDateValue ? moment(endDateValue) : moment(new Date()).startOf('day')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateFormItem
          fieldName={nameEndDate}
          required
          minDate={startDateValue ? moment(startDateValue) : undefined}
          maxDate={startDateValue ? moment(startDateValue).add(12, 'months') : undefined}
        />
      </Grid>
    </>
  );
}

export interface TextInputProps<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  placeholder?: string;
  required?: boolean;
}

export function TextInput<T extends FieldValues>({
  name,
  label,
  placeholder,
  required,
  ...controllerProps
}: TextInputProps<T>) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              {...getValidationPropsFromError(error)}
              inputRef={field.ref}
              placeholder={placeholder}
              fullWidth
            />
          )}
          name={name}
          {...controllerProps}
          rules={{ required: required, ...controllerProps.rules }}
        />
      </Grid>
    </>
  );
}

export interface ProgramSelectProps {
  name: string;
  programs: Program[];
  programType: ProgramType;
  multi?: boolean;
  label?: string;
  placeholder?: string;
}

export function ProgramSelect({
  name,
  programs,
  programType,
  multi,
  placeholder = 'Search for organizations or programs',
  label = multi ? '* Programs' : '* Program',
}: ProgramSelectProps) {
  const options = useMemo(
    () =>
      programs
        .filter((e) => filterProgram(e, programType))
        .sort((a, b) => a.programName.localeCompare(b.programName))
        .map((e) => ({ value: e.programId, label: e.programName })),
    [programType, programs]
  );

  return <SelectInput name={name} options={options} label={label} placeholder={placeholder} multi={multi} />;
}

export interface SelectInputProps {
  name: string;
  options: { value: string; label: string }[];
  label: string;
  placeholder?: string;
  multi?: boolean;
}

export function SelectInput({ name, options, label, placeholder, multi }: SelectInputProps) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <Select
                {...field}
                inputRef={field.ref}
                placeholder={placeholder}
                displayEmpty
                multiple={multi}
                renderValue={
                  multi
                    ? (selected) =>
                        selected.length
                          ? options
                              .filter((e) => selected.includes(e.value))
                              .map((e) => e.label)
                              .join(', ')
                          : placeholder
                    : undefined
                }
                error={Boolean(error)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400,
                    },
                  },
                }}
              >
                <MenuItem value={''} disabled>
                  {placeholder}
                </MenuItem>
                {options.map((value) => (
                  <MenuItem key={value.value} value={value.value}>
                    {multi && <Checkbox checked={field.value.includes(value.value)} />}
                    <ListItemText primary={value.label} />
                  </MenuItem>
                ))}
              </Select>
              {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
            </FormControl>
          )}
          name={name}
          rules={{ required: getRequiredValidationRule(name, true, true) }}
        />
      </Grid>
    </>
  );
}

function filterProgram(program: Program, programType: ProgramType): boolean {
  if (programType === ProgramType.Training) {
    return isTrainingProgram(program) && program.programDesignation === 'Registered';
  } else if (programType === ProgramType.Assessment) {
    return isAssessmentProgram(program) && program.programDesignation === 'Registered';
  }

  return false;
}
